import { Component, Input } from '@angular/core';
import { CardType } from '@shared/enums/card.type';

@Component({
  selector: 'simple-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title = '';

  @Input() value = '';

  @Input() type: CardType = CardType.Primary;

  cardType = CardType;
}
