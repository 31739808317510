import { Component, OnInit } from '@angular/core';
import { Logout } from '@core/ngrx-store/auth/auth.actions';
import {
  selectIsNotificationCleared,
  selectUserFullName,
  selectUserLoginRole,
} from '@core/ngrx-store/user/user.state';
import { ApiService } from '@core/services/api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { UtilService } from '@core/services/util.service';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hasNotification$ = new Observable<boolean>();

  userName$ = this.store.select(selectUserFullName);

  role$ = this.store.select(selectUserLoginRole);

  isNotificationCleared$ = this.store.select(selectIsNotificationCleared);

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly store: Store,
    private readonly apiService: ApiService,
    private readonly util: UtilService
  ) {}

  ngOnInit(): void {
    this.hasNotification$ = combineLatest([
      this.isNotificationCleared$,
      this.apiService.getIncomingMessages(),
    ]).pipe(
      map(([isNotificationCleared, messages]) => {
        return (
          !!messages.find(message => !message.isMessageRead) &&
          !isNotificationCleared
        );
      })
    );
  }

  logout() {
    this.store.dispatch(Logout());
    this.snackBarService.success('Kijelentkezett');
  }

  gotToMessages() {
    this.util.navigateToCommunication();
  }
}
