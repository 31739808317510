import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  idCardValidator,
  numberOnlyValidator,
  phoneNumberValidator,
} from './regex-patterns';

export const PhoneValidator = Validators.pattern(phoneNumberValidator);

export const IDValidator = Validators.pattern(idCardValidator);

export const NumberLengthValidator = (length: number) => {
  return Validators.pattern(numberOnlyValidator(length));
};

/** Custom validator for file upload */
export function FileRequiredValidator(
  control: AbstractControl
): ValidationErrors | null {
  const filePresent = (control.value as Array<File>).length > 0;
  if (filePresent) {
    return null;
  }
  return { fileRequired: true };
}

/**
 * Takes a function that determines whether the control is required or not.
 * @param predicate predicate function (should return boolean)
 * @returns ValidatorFn, required if the predicate is true, otherwise null
 */
export function RequiredIfValidator(
  predicate: (control: AbstractControl) => boolean
): ValidatorFn {
  return formControl => {
    if (predicate(formControl)) {
      return Validators.required(formControl);
    }
    return null;
  };
}
