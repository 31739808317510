<div class="card-container">
  <div class="card">
    <div class="flex justify-between">
      <div class="flex flex-col">
        <span class="title">{{ title }}</span>
        <span class="justify-end value">{{ value }}</span>
      </div>

      <div class="chart-container">
        <chart-element
          [chartData]="chartData"
          [size]="chartSize.Small"></chart-element>
      </div>
    </div>
  </div>
</div>
