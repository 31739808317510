import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocumentStatusType, DocumentType } from '@core/services/api.service';
import { SnackBarService } from '@core/services/snackbar.service';
import { Store } from '@ngrx/store';
import { BaseListComponent } from '@shared/classes/base.list.component';
import { FileUploadDialogComponent } from '@shared/components/file-upload-dialog/file-upload-dialog.component';
import { filter, switchMap, takeUntil } from 'rxjs';
import { DocumentCategory } from '@shared/enums/documentCategory';
import { DocumentService } from '@shared/services/document.service';
import { SetStudent } from '../../../../features/management/modules/company/modules/company-edit/ngrx-store/company-edit.actions';
import { DoucmentValidationDialogComponent } from '../../../../features/management/modules/company/modules/company-edit/modules/student/dialogs/doucment-validation-dialog/doucment-validation-dialog.component';
import {
  DocumentValidationData,
  DocumentValidationTextData,
} from '../../../../features/management/modules/company/modules/company-edit/modules/student/interfaces/documentValidationData';
import { StudentDocument } from '../../../../features/management/modules/company/modules/company-edit/modules/student/interfaces/studentDocument';
import { StudentService } from '../../../../features/management/modules/company/modules/company-edit/modules/student/services/student.service';
import { isFileExist } from '@core/utilities/filter';
import { StudentUtilService } from '../../../../features/management/modules/company/modules/company-edit/modules/student/services/student.util.service';
import { CertificateDetailsDialogComponent } from 'src/app/features/management/modules/company/modules/company-edit/modules/student/dialogs/certificate-details-dialog/certificate-details-dialog.component';
import { Role } from '@core/enums/app.role';
import { notUndefined } from '@core/ngrx-store/extension-methods';
import { LocalizedMatTableDataSource } from '@shared/classes/localized-mat-table-data-source';

@Component({
  selector: 'student-document',
  templateUrl: './student-document.component.html',
  styleUrls: ['./student-document.component.scss'],
})
export class StudentDocumentComponent extends BaseListComponent<StudentDocument> {
  @Input() companyId = '';
  @Input() studentGroupId = '';
  @Input() studentId: string | null = null;
  @Input() isStudent = false;
  @Input() role: Role | null = null;

  override displayedColumns: string[] = [
    'name',
    'validatedBy',
    'validatedAt',
    'createdBy',
    'createdAt',
    'status',
    'upload',
    'download',
    'validationNeeded',
  ];

  status = DocumentStatusType;

  type = DocumentType;

  typeUploading: DocumentType | null = null;

  typeDownloading: DocumentType | null = null;

  typeValidating: DocumentType | null = null;

  selectedId: string | null = null;

  userRole = Role;

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly dialog: MatDialog,
    private readonly studentService: StudentService,
    private readonly documentService: DocumentService,
    private readonly studentUtilService: StudentUtilService,
    private readonly store: Store
  ) {
    super();
  }

  openDialog(document: StudentDocument, studentId: string) {
    if (
      document.type === DocumentType.QualificationCertificate &&
      document.status !== DocumentStatusType.WaitingForUpload &&
      document.status !== DocumentStatusType.Rejected
    )
      return;
    const fileUploadDialog: MatDialogRef<FileUploadDialogComponent, File[]> =
      this.dialog.open(FileUploadDialogComponent);
    fileUploadDialog
      .afterClosed()
      .pipe(filter(isFileExist), takeUntil(this.destroy$))
      .subscribe(files => {
        this.selectedId = document.id;
        this.typeUploading = document.type;
        if (this.isStudent) {
          this.uploadOwnFile(
            files[0],
            document.category,
            document.type,
            studentId,
            document.id
          );
        } else {
          this.uploadFile(
            files[0],
            document.category,
            document.type,
            studentId,
            document.id
          );
        }
      });
  }

  openCertificateDetailsDialog(document: StudentDocument) {
    if (document.type !== DocumentType.QualificationCertificate) {
      return;
    }
    this.documentService
      .getStudentCertificateDetails(document.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(certificateDetails => {
        this.dialog.open(CertificateDetailsDialogComponent, {
          data: certificateDetails.notes,
          width: '80vw',
        });
      });
  }

  uploadFile(
    file: File,
    category: DocumentCategory,
    type: DocumentType,
    studentId: string,
    documentId: string
  ) {
    this.documentService
      .uploadFile(file, type, studentId, category, documentId)
      .pipe(
        switchMap(_ => {
          return this.studentService.get(
            this.companyId,
            this.studentGroupId,
            studentId
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(student => {
        this.dataSource = new LocalizedMatTableDataSource(
          this.studentUtilService.loadStudentDocuments(student.studentDocuments)
        );
        this.store.dispatch(SetStudent(student));
        this.snackBarService.success('Fájl feltöltve!');
      })
      .add(() => {
        this.typeUploading = null;
        this.selectedId = null;
      });
  }

  uploadOwnFile(
    file: File,
    category: DocumentCategory,
    type: DocumentType,
    studentId: string,
    documentId: string
  ) {
    this.documentService
      .uploadFile(file, type, studentId, category, documentId)
      .pipe(
        switchMap(_ => {
          return this.studentService.getStudentOwnData();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(student => {
        this.dataSource = new LocalizedMatTableDataSource(
          this.studentUtilService.loadStudentDocuments(student.studentDocuments)
        );
        this.snackBarService.success('Fájl feltöltve!');
      })
      .add(() => {
        this.typeUploading = null;
        this.selectedId = null;
      });
  }

  downloadFile(document: StudentDocument) {
    if (
      document.status === DocumentStatusType.WaitingForUpload ||
      !document.id
    ) {
      return;
    }
    this.typeDownloading = document.type;
    this.selectedId = document.id;
    this.documentService
      .downloadFile(document.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.documentService.saveFileResponse(res);
        this.snackBarService.success('Fájl letöltve!');
      })
      .add(() => {
        this.typeDownloading = null;
        this.selectedId = null;
      });
  }

  documentValidation(isApproved: boolean, document: StudentDocument) {
    const doucmentValidationDialog: MatDialogRef<
      DoucmentValidationDialogComponent,
      DocumentValidationTextData
    > = this.dialog.open(DoucmentValidationDialogComponent, {
      data: { type: isApproved },
      width: '40vw',
    });
    doucmentValidationDialog
      .afterClosed()
      .pipe(filter(notUndefined), takeUntil(this.destroy$))
      .subscribe(data => {
        const documentValidationData: DocumentValidationData = {
          isApproved: isApproved,
          documentId: document.id,
          status: document.status,
          text: data.noteText,
        };
        this.validateDocument(documentValidationData, document.type);
      });
  }

  validateDocument(
    documentValidationData: DocumentValidationData,
    type: DocumentType
  ) {
    const studentId = this.studentId;
    if (!studentId) return;
    this.typeValidating = type;
    this.studentService
      .validateDocument(documentValidationData)
      .pipe(
        switchMap(_ => {
          return this.studentService.get(
            this.companyId,
            this.studentGroupId,
            studentId
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(student => {
        this.dataSource = new LocalizedMatTableDataSource(
          this.studentUtilService.loadStudentDocuments(student.studentDocuments)
        );
        this.store.dispatch(SetStudent(student));
        this.snackBarService.success('Bizonyítvány validálva!');
      })
      .add(() => (this.typeValidating = null));
  }
}
