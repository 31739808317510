<div class="flex justify-between items-start">
  <h2 mat-dialog-title translate>Student.Dialog.Validation</h2>
  <button mat-dialog-close>
    <img
      class="grey-filter"
      width="24px"
      src="assets/images/icons/cancel.svg"
      alt="" />
  </button>
</div>
<form [formGroup]="documentValidationDataForm">
  <div class="content">
    <div class="flex">
      <mat-form-field
        floatLabel="always"
        class="form-field"
        appearance="outline">
        <mat-label translate>Indokás</mat-label>
        <textarea
          matInput
          placeholder="Indokás"
          formControlName="noteText"
          cdkTextareaAutosize
          cdkAutosizeMinRows="6"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>
<mat-dialog-actions class="gap-4">
  <button
    [disabled]="documentValidationDataForm.invalid"
    mat-flat-button
    (click)="confirmValidation()">
    {{ 'General.Save' | translate }}
  </button>
  <button mat-dialog-close mat-flat-button>
    {{ 'General.Reset' | translate }}
  </button>
</mat-dialog-actions>
