<form [formGroup]="roleSelectForm">
  <div class="field-group">
    <p class="mb-4 text-primary text-2xl font-bold" translate>
      Landing.Login.SelectRole
    </p>
    <div class="content mb-[4rem]">
      <div class="flex">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label translate>Landing.Login.Role</mat-label>
          <mat-select formControlName="selectedRole">
            <mat-option *ngFor="let role of data.roles" [value]="role">
              {{ 'Enum.Role.' + role | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="flex">
    <button
      mat-flat-button
      [disabled]="!roleSelectForm.valid"
      (click)="selectRole(roleSelectForm.getRawValue())">
      {{ 'General.Select' | translate }}
    </button>
  </div>
</form>
