<ng-container>
  <span [ngClass]="textClasses" [innerHTML]="displayText"></span>
  <span
    *ngIf="displayText !== text"
    [ngClass]="moreClasses"
    (click)="showMore()">
    {{ more }}
  </span>
  <span
    *ngIf="shrinkable && displayText.length > max"
    [ngClass]="lessClasses"
    (click)="showLess()">
    {{ less }}
  </span>
</ng-container>
