import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { LocalizedMatTableDataSource } from '@shared/classes/localized-mat-table-data-source';
import { Subject } from 'rxjs';

/** This type defines what types are supported for display in a dynamic table component. */
type TDisplay = string | number | boolean | Date;

/** Defines how a specific property (column) of an object should be displayed in a dynamic table. */
export type DynamicColumnDefinition<T> = {
  /** The targeted property of the object */
  key: keyof T & string;
  /** Desired label in table header. Defaults to property name on the object. */
  headerLabel?: string;
  /** Allows customization of display values so the original data doesn't need to be mutated. Defaults to displaying the original value. */
  map?: (dataValue: any) => TDisplay;
  /**
   * If the targeted property is a `Date`, it will be displayed with this date formatting.
   * Default is: `'MMM d, y hh:mm'` i.e: 'Feb 10, 2023 02:24'
   */
  dateFormat?: string;
  /** If the targeted property is a string, this flag can be set to pipe translation to it on the display. Defaults to false */
  translate?: boolean;
};

/**
 * Dynamically displays a data set.
 * Only the columns defined in `columnMapping` will be displayed.
 */
@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent<T extends Record<keyof T, TDisplay>>
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild(MatTable, { static: true }) table!: MatTable<T>;
  /** Input css classes for the displayed table */
  @Input() tableClasses: string[] = ['table'];
  /** The data to be displayed */
  @Input() data: T[] | null = null;
  /** Array of column definitions. Only the columns specified here will be displayed, in the same order they appear in this array. */
  @Input() columnMapping: DynamicColumnDefinition<T>[] | null = null;
  /** Tunnels filter input to dataSource (exposes mat table filter functionality). */
  @Input() filter = '';

  sort!: MatSort;
  dataSource = new LocalizedMatTableDataSource<T>();
  private readonly destroy$ = new Subject<void>();

  /** This action will be fired when a table row gets clicked */
  @Input() rowAction: (row: T) => void = row => {
    // default action is logging
    console.log(row);
  };

  @ViewChild(MatSort) set matSort(matSort: MatSort) {
    this.sort = matSort;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.setDataSourceAttributes();
  }

  /** Handle changes */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data') && this.data) {
      this.updateData(this.data);
    }
    if (changes.hasOwnProperty('filter') && this.filter) {
      this.updateFilter(this.filter);
    }
  }
  /** Determines the type of a cell value */
  typeof(value: TDisplay): string {
    if (value instanceof Date) return 'Date';
    else if (typeof value === 'string') return 'string';
    else if (typeof value === 'number') return 'number';
    else if (typeof value === 'boolean') return 'boolean';
    else return 'unsupported';
  }

  /** Maps a cell's value if there is a mapping defined. Returns the original value otherwise */
  mappedValue(column: DynamicColumnDefinition<T>, data: T): TDisplay {
    return column.map ? column.map(data[column.key]) : data[column.key];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /** If the data changes, the dataSource gets updated */
  private updateData(data: T[]) {
    this.dataSource.data = data;
  }

  /** Destroys current filter subscription and subscribes to new observable. Applies the new filter to the dataSource. */
  private updateFilter(filter: string) {
    this.dataSource.filter = filter;
  }

  get displayColumnKeys(): string[] {
    if (!this.columnMapping) {
      return [];
    }
    return this.columnMapping.map(column => column.key);
  }
}
