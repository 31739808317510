import { DocumentStatusType, DocumentType } from '@core/services/api.service';
import { StudentDocument } from '../interfaces/studentDocument';
import { DocumentCategory } from '@shared/enums/documentCategory';

export const otherDocument: StudentDocument = {
  id: '',
  type: DocumentType.Other,
  category: DocumentCategory.Student,
  name: '',
  validatedBy: ' - ',
  validatedAt: null,
  createdBy: ' - ',
  createdAt: null,
  status: DocumentStatusType.WaitingForUpload,
  validationNeeded: false,
} as const;

export const STUDENT_DOCUMENTS: StudentDocument[] = [
  {
    id: '',
    type: DocumentType.StudentContract,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: false,
  },
  {
    id: '',
    type: DocumentType.MedicalCertificate,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: false,
  },
  {
    id: '',
    type: DocumentType.EmploymentContract,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: false,
  },
  {
    id: '',
    type: DocumentType.QualificationCertificate,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: true,
  },
  {
    id: '',
    type: DocumentType.BaseExamResult,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: false,
  },
  {
    id: '',
    type: DocumentType.ExemptionFromBaseExam,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.WaitingForUpload,
    validationNeeded: false,
  },
  {
    id: '',
    type: DocumentType.CauseOfInactivation,
    category: DocumentCategory.Student,
    name: '',
    validatedBy: ' - ',
    validatedAt: null,
    createdBy: ' - ',
    createdAt: null,
    status: DocumentStatusType.Approved,
    validationNeeded: false,
  },
];
