import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarComponent } from '../components/snackbar/snackbar.component';
import { SnackBarType } from '../enums/snackbar.type';
import { SnackBarData } from '../interfaces/snackbar.data';

@Injectable()
export class SnackBarService {
  private readonly queue: MatSnackBarConfig<SnackBarData>[] = [];

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translate: TranslateService
  ) {}
  info(message: string) {
    return this.open(message);
  }

  success(message: string) {
    return this.open(message, SnackBarType.Success, ['snackbar', 'success']);
  }

  warning(message: string) {
    return this.open(message, SnackBarType.Warning, ['snackbar', 'warning']);
  }

  error(message: string) {
    return this.open(message, SnackBarType.Error, ['snackbar', 'error']);
  }

  open(
    message: string,
    type = SnackBarType.Info,
    panelClass: string | string[] = ['snackbar', 'info'],
    duration = 3500
  ) {
    message = this.translate.instant(message) as string;
    const config: MatSnackBarConfig<SnackBarData> = {
      data: {
        type: type,
        message: message,
      },
      panelClass: panelClass,
      duration: duration,
    };
    this.queue.push(config);
    if (this.queue.length === 1) {
      this.openFromComponent(config);
    }
  }

  private openFromComponent(
    config: MatSnackBarConfig<SnackBarData>
  ): MatSnackBarRef<SnackBarComponent> {
    const snackBarRef = this.snackBar.openFromComponent<
      SnackBarComponent,
      SnackBarData
    >(SnackBarComponent, config);
    snackBarRef.afterDismissed().subscribe(() => {
      this.queue.shift();
      if (this.queue.length) {
        this.openFromComponent(this.queue[0]);
      }
    });
    return snackBarRef;
  }
}
