import { Environment } from '@core/interfaces/environment';

export const environment: Environment = {
  api: {
    mock: false,
    url: '/api',
    maxUnauthorizedAttempts: 2,
    queryDebounceTime: 500,
  },
  app: {
    name: 'Manabi',
  },
  internalization: {
    language: {
      default: 'hu',
    },
  },
  production: false,
};
