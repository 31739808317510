import { Role } from '@core/enums/app.role';
import { LoginData, LoginDetailsView } from '@core/services/api.service';
import { createAction, props } from '@ngrx/store';

interface AuthenticationData extends Omit<LoginDetailsView, 'userRoles'> {
  role: Role;
}

export const Authentication = createAction(
  '[Auth] Login',
  props<{ data: LoginData }>()
);

export const SuccessfulAuthentication = createAction(
  '[Auth] Successful auth',
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (data: AuthenticationData, navigate: boolean = true) => ({ data, navigate })
);

export const FailedAuthentication = createAction(
  '[Auth] Failed auth',
  props<{ error: Error }>()
);

export const ChooseRole = createAction(
  '[Auth] Choose role',
  props<{ data: LoginDetailsView }>()
);
export const Logout = createAction('[Auth] Logout');
export const ResetAuthentication = createAction('[Auth] Reset');
