import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const FILE_UPLOAD_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true,
};
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [FILE_UPLOAD_VALUE_ACCESSOR],
})
export class FileUploadComponent implements ControlValueAccessor {
  /** Exposes files to parent component */
  @Output() updatedFilesEvent = new EventEmitter<Array<File>>();
  @Input() label = 'Dokumentum feltöltése<br>Kattints vagy dobd ide!';
  @Input() files: File[] = [];
  @Input() multiple = false;
  @Input() acceptedFiles = '';
  disabled = false;

  onFileChange(pFileList: File[]) {
    pFileList.forEach((element, idx) => {
      if (!element) return;
      this.files[idx] = element;
    });
    this.updateUploadedFiles(pFileList);
  }

  onFileChangeClick(event: Event) {
    const eventTarget = event.target as HTMLInputElement;
    if (eventTarget.files && eventTarget.files.length) {
      const uploadedFiles = Array.from(eventTarget.files);
      uploadedFiles.forEach((element, idx) => {
        this.files[idx] = element;
      });
      this.updateUploadedFiles(this.files);
    }
  }

  updateUploadedFiles(value: Array<File>) {
    this.updatedFilesEvent.emit(value);
    this._onChange(value);
  }

  registerOnTouched(fnc: () => undefined): void {
    this._onTouch = fnc;
  }
  registerOnChange(fnc: (value: File[]) => undefined): void {
    this._onChange = fnc;
  }
  writeValue(input: File[]): void {
    if (input.length !== this.files.length) {
      this.files = input;
      this._onTouch();
    }
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _onChange = (_: File[]) => undefined;
  private _onTouch = () => undefined;
}
