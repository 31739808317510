<div class="flex justify-between items-start">
  <h2 mat-dialog-title translate>Fájl feltöltése</h2>
  <button mat-dialog-close>
    <img
      class="grey-filter"
      width="24px"
      src="assets/images/icons/cancel.svg"
      alt="" />
  </button>
</div>
<div mat-dialog-content>
  <app-file-upload
    [acceptedFiles]="acceptedFiles"
    (updatedFilesEvent)="updateUploadedFiles($event)"></app-file-upload>
</div>
<mat-dialog-actions class="gap-4">
  <button mat-flat-button (click)="confirmUpload()" [disabled]="uploadDisabled">
    {{ 'General.Upload' | translate }}
  </button>
  <button mat-flat-button mat-dialog-close>
    {{ 'General.Reset' | translate }}
  </button>
</mat-dialog-actions>
