import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeatureWrapperComponent } from '@core/components/feature-wrapper/feature-wrapper.component';
import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { NotAuthenticatedGuard } from '@core/guards/not-authenticated.guard';
import { RoleGuard } from '@core/guards/role.guard';
import { ElearningRoles } from '@core/utilities/required-roles/elearning.required-roles';

const routes: Routes = [
  {
    path: '',
    component: FeatureWrapperComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/main/main.module').then(
            module => module.MainModule
          ),
        canActivate: [AuthenticatedGuard()],
      },
      {
        path: 'management',
        loadChildren: () =>
          import('./features/management/management.module').then(
            module => module.ManagementModule
          ),
        canActivate: [AuthenticatedGuard()],
      },
      {
        path: 'elearning',
        loadChildren: () =>
          import('./features/elearning/elearning.module').then(
            module => module.ElearningModule
          ),
        canActivate: [AuthenticatedGuard(), RoleGuard(ElearningRoles)],
      },
    ],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./features/landing/landing.module').then(
        module => module.LandingModule
      ),
    canActivate: [NotAuthenticatedGuard()],
  },
  {
    path: 'login',
    redirectTo: 'landing/login',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
