import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';

@Injectable()
export class ChartService {
  registerChartTextPlugin(firstLine: string, secondLine: string | null = null) {
    return {
      id: 'pluginId',
      beforeDraw: (chart: Chart) => {
        const width = chart.width,
          height = chart.height;

        const fontSize = (height / 100).toFixed(2);
        chart.ctx.font = 'bold ' + fontSize + 'em sans-serif';
        chart.ctx.textBaseline = 'middle';

        const textX = Math.round(
            (width - chart.ctx.measureText(firstLine).width) / 2
          ),
          textY = secondLine ? height / 2.2 : height / 1.9;

        chart.ctx.fillStyle = secondLine ? '#3c7afc' : '#53c4a1';
        chart.ctx.fillText(firstLine, textX, textY);

        if (secondLine) {
          const textLowerX = Math.round(
            (width - chart.ctx.measureText(secondLine).width) / 2
          );
          chart.ctx.fillText(secondLine, textLowerX, textY + 30);
        }
      },
    };
  }

  createChart(
    nativeElement: HTMLCanvasElement,
    completed: number,
    remaining: number,
    firstLine: string,
    secondLine: string | null = null
  ) {
    return new Chart(nativeElement, {
      type: 'doughnut',
      plugins: [this.registerChartTextPlugin(firstLine, secondLine)],
      data: {
        datasets: [
          {
            data: [remaining, completed],
            backgroundColor: ['#53c4a1'],
            borderWidth: [secondLine ? 8 : 7, 0],
            hoverBorderColor: ['#ffffff'],
          },
        ],
      },
      options: {
        cutout: secondLine ? 60 : 45,
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
        responsive: false,
      },
    });
  }
}
