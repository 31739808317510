import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Role } from '@core/enums/app.role';
import { notNull } from '@core/ngrx-store/extension-methods';
import { selectUserLoginRole } from '@core/ngrx-store/user/user.state';
import { UtilService } from '@core/services/util.service';
import { Store } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';

@Directive({
  selector: '[requiredRoles]',
})
export class RequiredRoleDirective implements OnInit, OnDestroy {
  // the role the user must have
  @Input() requiredRoles: Role[] = [];
  isVisible = false;
  private readonly destroy$ = new Subject<void>();

  /**
   * @param {ViewContainerRef} viewContainerRef
   * The location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   * The templateRef to be potentially rendered
   * @param {Store} store
   * Will give us access to the roles a user has
   */
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly store: Store,
    private readonly util: UtilService
  ) {}

  ngOnInit() {
    this.store
      .select(selectUserLoginRole)
      .pipe(filter(notNull), takeUntil(this.destroy$))
      .subscribe(role => {
        if (this.requiredRoles.length === 0) {
          this.viewContainerRef.clear();
          return;
        }
        // If the user has the role needed to
        // render this component we can add it
        if (this.util.checkRole(role, this.requiredRoles)) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
