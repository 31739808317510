<div *ngIf="messageList; else loading" class="message-list-container">
  <div
    *ngIf="currentMessagesToShow.length === 0; else listDisplay"
    class="no-messages blue-box">
    Nincsen üzenete
  </div>

  <ng-template #listDisplay>
    <div
      *ngFor="let messageContent of currentMessagesToShow; index as i"
      [ngClass]="{
        selected: selectedMessage?.id === messageContent.id
      }"
      (click)="messageClick(messageContent)"
      class="message-preview blue-box bg-transition flex flex-col text-sm">
      <div class="top-row mb-5">
        <span *ngIf="incoming">{{ messageContent.senderUser.fullName }}</span>
        <span *ngIf="!incoming">{{
          messageContent.recipientUser.fullName
        }}</span>
      </div>
      <div class="flex flex-row justify-between">
        <span class="text-primary font-bold mr-8">{{
          messageContent.createdAt + 'Z' | date : 'yyyy. MM. dd. HH:mm'
        }}</span>
        <span
          *ngIf="messageContent.isMessageRead"
          class="text-secondary"
          translate
          >Communication.MessageState.Read</span
        >
        <span
          *ngIf="!messageContent.isMessageRead"
          class="text-secondary font-bold"
          translate
          >Communication.MessageState.New</span
        >
      </div>
      <div class="flex flex-row justify-between">
        <span *ngIf="incoming" translate
          >Enum.Role.{{ messageContent.senderUser.role }}</span
        >
        <span *ngIf="!incoming" translate
          >Enum.Role.{{ messageContent.recipientUser.role }}</span
        >
        <span class="ml-2" translate
          >Enum.MessageType.{{ messageContent.messageTopic }}</span
        >
      </div>
    </div>
  </ng-template>

  <mat-paginator
    [length]="messageCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="paginationOptions"
    (page)="onPageChange($event)">
  </mat-paginator>
</div>

<ng-template #loading>
  <loading-block></loading-block>
</ng-template>
