import {
  Directive,
  HostListener,
  HostBinding,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

@Directive({
  selector: '[fileDragDrop]',
})
export class FileDragNDropDirective {
  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter<
    File[]
  >();
  @Input() multiple = false;
  @HostBinding('style.border') private borderStyle = '2px dashed';
  @HostBinding('style.border-color') private borderColor = '#696D7D';
  @HostBinding('style.border-radius') private borderRadius = '5px';

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = 'cadetblue';
    this.borderStyle = '3px solid';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#696D7D';
    this.borderStyle = '2px dashed';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.borderColor = '#696D7D';
    this.borderStyle = '2px dashed';
    const files = Array.from(evt.dataTransfer?.files || []);
    if (files) {
      const validFiles = this.multiple ? files : [files[0]];
      this.filesChangeEmiter.emit(validFiles);
    }
  }
}
