import { Role } from '@core/enums/app.role';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface UserDataState {
  email: string | null;
  fullName: string | null;
  phoneNumber: string | null;
  loginRole: Role | null;
  isNotificationCleared: boolean;
}

export const defaultUserDataState: UserDataState = {
  email: null,
  fullName: null,
  phoneNumber: null,
  loginRole: null,
  isNotificationCleared: false,
};

const selectUserDataState =
  createFeatureSelector<UserDataState>('UserDataFeature');

export const selectUserEmail = createSelector(
  selectUserDataState,
  state => state.email
);

export const selectUserFullName = createSelector(
  selectUserDataState,
  state => state.fullName
);

export const selectUserPhoneNumber = createSelector(
  selectUserDataState,
  state => state.phoneNumber
);

export const selectUserLoginRole = createSelector(
  selectUserDataState,
  state => state.loginRole
);

export const selectIsNotificationCleared = createSelector(
  selectUserDataState,
  state => state.isNotificationCleared
);
