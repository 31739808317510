import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from '@core/enums/app.role';
import { UtilService } from '@core/services/util.service';
import { DerivedFormGroup } from '@shared/types/form.control';
import { RoleData } from '../../interfaces/roleData';

@Component({
  selector: 'role-select-dialog',
  templateUrl: './role-select-dialog.component.html',
  styleUrls: ['./role-select-dialog.component.scss'],
})
export class RoleSelectDialogComponent {
  roleSelectForm: DerivedFormGroup<RoleData>;

  roleType = Role;

  constructor(
    private readonly util: UtilService,
    private readonly dialogRef: MatDialogRef<RoleSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { roles: string[] }
  ) {
    this.roleSelectForm = this.util.createRoleSelectForm();
  }

  selectRole(roleData: RoleData) {
    this.dialogRef.close(roleData.selectedRole);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
