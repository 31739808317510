import { Injectable } from '@angular/core';
import {
  FormControl,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import {
  HighestQualificationType,
  NoteData,
  StudentData,
  DocumentType,
  DocumentListView,
} from '@core/services/api.service';
import {
  IDValidator,
  NumberLengthValidator,
  PhoneValidator,
} from '@shared/form-validators/form-validators';
import { DerivedFormGroup } from '@shared/types/form.control';
import cloneDeep from 'lodash.clonedeep';
import { StudentTabs } from '../enums/studentTab';
import { DocumentValidationTextData } from '../interfaces/documentValidationData';
import { otherDocument, STUDENT_DOCUMENTS } from './documents';

@Injectable()
export class StudentUtilService {
  constructor(private readonly fb: NonNullableFormBuilder) {}

  createStudentDataForm(): DerivedFormGroup<StudentData> {
    return this.fb.group({
      fullName: ['', Validators.required],
      maidenFullName: ['', Validators.required],
      placeOfBirth: ['', Validators.required],
      dateOfBirth: [null as unknown as Date, Validators.required],
      mothersMaidenFullName: ['', Validators.required],
      address: ['', Validators.required],
      taj: ['', [Validators.required, NumberLengthValidator(9)]],
      taxIdNumber: ['', [Validators.required, NumberLengthValidator(10)]],
      idCardNumber: ['', [Validators.required, IDValidator]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: new FormControl<string | null>(null, PhoneValidator),
      vocationalTrainingStudentId: new FormControl<string | null>(
        null,
        NumberLengthValidator(11)
      ),
      highestQualification: [
        null as unknown as HighestQualificationType,
        Validators.required,
      ],
      qualificationCertificateNumber: new FormControl<string | null>(null),
      okjId: new FormControl<string | null>(null, NumberLengthValidator(6)),
      hasGraduatedHighSchool: [false, Validators.required],
      startDateOfCurrentEmployment: [new Date(), Validators.required],
      jobTitle: ['', Validators.required],
      note: new FormControl<string | null>(null),
    });
  }

  createStudentInactivationForm(): DerivedFormGroup<NoteData> {
    return this.fb.group({
      text: new FormControl<string | null>(null, Validators.required),
    });
  }

  createDocumentValidationDataForm(): DerivedFormGroup<DocumentValidationTextData> {
    return this.fb.group({
      noteText: [''],
    });
  }

  loadStudentDocuments(documents: DocumentListView[]) {
    const combined = this.defaultStudentDocuments.map(studentDocument => {
      const received = documents.find(
        document => document.type === studentDocument.type
      );
      if (received) {
        return { ...studentDocument, ...received };
      }
      return studentDocument;
    });

    documents.forEach(studentDocument => {
      if (studentDocument.type === DocumentType.Other) {
        combined.push({
          ...otherDocument,
          ...studentDocument,
          name: studentDocument.fileName,
        });
      }
    });

    const combinedWithInactivation = combined.filter(
      document =>
        document.type !== DocumentType.CauseOfInactivation ||
        (document.type === DocumentType.CauseOfInactivation &&
          document.id !== '')
    );

    return [...combinedWithInactivation, otherDocument];
  }

  getStudentTabIndex(tabName: keyof typeof StudentTabs) {
    const tab = StudentTabs[tabName];
    if (tab === undefined) return StudentTabs.BaseData;
    return tab;
  }

  private get defaultStudentDocuments() {
    return cloneDeep(STUDENT_DOCUMENTS);
  }
}
