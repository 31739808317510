import { Component, Input } from '@angular/core';
import {
  HighestQualificationListItem,
  NoteListView,
  StudentData,
} from '@core/services/api.service';
import { DerivedFormGroup } from '@shared/types/form.control';

@Component({
  selector: 'student-base-data',
  templateUrl: './student-base-data.component.html',
  styleUrls: ['./student-base-data.component.scss'],
})
export class StudentBaseDataComponent {
  @Input() studentBaseDataForm: DerivedFormGroup<StudentData> | null = null;

  @Input() studentNoteList: NoteListView[] = [];

  @Input() highestQualifications: HighestQualificationListItem[] = [];
}
