import { createFeatureSelector, createSelector } from '@ngrx/store';
import { environment } from 'src/environments/environment';

export interface EnvironmentState {
  language: string;
  lastEmail: string | null;
}

export const defaultEnvironmentState: EnvironmentState = {
  language: environment.internalization.language.default,
  lastEmail: null,
};

const selectEnvironmentState =
  createFeatureSelector<EnvironmentState>('EnvironmentFeature');

export const selectLanguage = createSelector(
  selectEnvironmentState,
  state => state.language
);

export const selectLastEmail = createSelector(
  selectEnvironmentState,
  state => state.lastEmail
);
