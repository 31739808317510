import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { selectAccessToken } from '@core/ngrx-store/auth/auth.state';
import { UtilService } from '@core/services/util.service';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

export function AuthenticatedGuard(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const util: UtilService = inject(UtilService);

    return store.select(selectAccessToken).pipe(
      map(token => token !== null),
      take(1),
      map(authenticated => {
        if (!authenticated) {
          util.navigateToLogin();
        }
        return authenticated;
      })
    );
  };
}
