import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogType } from '../enums/dialog.type';
import { DialogData } from '../interfaces/dialog.data';

@Injectable()
export class DialogService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService
  ) {}

  info(title: string, content: string) {
    return this.openDialog(title, content);
  }

  success(title: string, content: string) {
    return this.openDialog(title, content, DialogType.Success, [
      'dialog',
      'success',
    ]);
  }

  warning(title: string, content: string) {
    return this.openDialog(title, content, DialogType.Warning, [
      'dialog',
      'warning',
    ]);
  }

  error(title: string, content: string) {
    return this.openDialog(title, content, DialogType.Error, [
      'dialog',
      'error',
    ]);
  }

  openDialog(
    title: string,
    content: string,
    type = DialogType.Info,
    panelClass: string | string[] = ['dialog', 'info'],
    width = 'auto'
  ): MatDialogRef<unknown> {
    const config: MatDialogConfig<DialogData> = {
      data: {
        type: type,
        title: title,
        content: content,
      },
      width: width,
      panelClass: panelClass,
    };
    this.translateFields(config);
    return this.open(config);
  }

  openDialogWithTemplate(
    titleTemplateRef: TemplateRef<ElementRef>,
    contentTemplateRef: TemplateRef<ElementRef>,
    type = DialogType.Info,
    panelClass: string | string[] = ['dialog', 'info'],
    width = 'auto'
  ): MatDialogRef<unknown> {
    const config: MatDialogConfig<DialogData> = {
      data: {
        type: type,
        titleRef: titleTemplateRef,
        contentRef: contentTemplateRef,
      },
      width: width,
      panelClass: panelClass,
    };
    return this.open(config);
  }

  private translateFields(config: MatDialogConfig<DialogData>) {
    if (config.data?.title) {
      config.data.title = this.translate.instant(config.data?.title) as string;
    }
    if (config.data?.content) {
      config.data.content = this.translate.instant(
        config.data.content
      ) as string;
    }
  }

  private open(config: MatDialogConfig<DialogData>): MatDialogRef<unknown> {
    return this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      config
    );
  }
}
