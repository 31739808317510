import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  CustomKeyboardEvent,
  NgxMaskDirective,
  NgxMaskService,
} from 'ngx-mask';
@Directive({
  selector: 'input[phone-input]',
  providers: [NgxMaskService],
})
export class PhoneInputMaskDirective
  extends NgxMaskDirective
  implements ControlValueAccessor, OnChanges, Validator
{
  @Input() override maskExpression = '+00-00-000-0000';
}
