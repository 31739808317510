import { Injectable } from '@angular/core';
import {
  FormControl,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Role } from '@core/enums/app.role';
import { DerivedFormGroup } from '@shared/types/form.control';
import { RoleSelectDialogComponent } from 'src/app/features/landing/dialogs/role-select-dialog/role-select-dialog.component';
import { RoleData } from 'src/app/features/landing/interfaces/roleData';
import { LoginDetailsView } from './api.service';

@Injectable()
export class UtilService {
  constructor(
    private readonly router: Router,
    private readonly fb: NonNullableFormBuilder,
    private readonly dialog: MatDialog
  ) {}

  navigateToDefault() {
    void this.router.navigate(['/home']);
  }

  navigateToLogin() {
    void this.router.navigate(['/landing/login']);
  }

  navigateToCommunication() {
    void this.router.navigate(['/management/communication/inbox/']);
  }

  checkRole(userRole: Role | null, requiredRoles: Role[]) {
    return !!requiredRoles.find(role => role === userRole);
  }

  createRoleSelectForm(): DerivedFormGroup<RoleData> {
    return this.fb.group({
      selectedRole: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  openRoleSelectDialog(loginDetails: LoginDetailsView) {
    const roleSelectDialogRef: MatDialogRef<RoleSelectDialogComponent, Role> =
      this.dialog.open(RoleSelectDialogComponent, {
        data: { roles: loginDetails.userRoles },
        width: '30rem',
        disableClose: true,
      });
    return roleSelectDialogRef.afterClosed();
  }
}
