import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DerivedFormGroup } from '@shared/types/form.control';
import { DocumentValidationTextData } from '../../interfaces/documentValidationData';
import { StudentUtilService } from '../../services/student.util.service';

@Component({
  selector: 'doucment-validation-dialog',
  templateUrl: './doucment-validation-dialog.component.html',
  styleUrls: ['./doucment-validation-dialog.component.scss'],
})
export class DoucmentValidationDialogComponent {
  documentValidationDataForm: DerivedFormGroup<DocumentValidationTextData>;

  constructor(
    private documentValidationDialogRef: MatDialogRef<
      DoucmentValidationDialogComponent,
      DocumentValidationTextData
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: { type: boolean },
    private readonly util: StudentUtilService
  ) {
    this.documentValidationDataForm =
      this.util.createDocumentValidationDataForm();
    if (!data.type) {
      this.documentValidationDataForm.controls['noteText'].setValidators(
        Validators.required
      );
    }
  }

  confirmValidation() {
    this.documentValidationDialogRef.close(
      this.documentValidationDataForm.getRawValue()
    );
  }
}
