import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { NgxMaskService } from 'ngx-mask';
@Directive({
  selector: '[phone]',
  providers: [NgxMaskService],
})
export class PhoneMaskDirective implements AfterContentInit {
  @Input() mask = '+00-00-000-0000';
  element: HTMLSpanElement;

  constructor(
    private readonly elemRef: ElementRef,
    private readonly ngxMask: NgxMaskService
  ) {
    this.element = this.elemRef.nativeElement as HTMLSpanElement;
  }

  ngAfterContentInit() {
    this.element.textContent = this.ngxMask.applyMask(
      this.element.textContent || '',
      this.mask
    );
  }
}
