import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LongTextComponent } from './components/long-text/long-text.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { SharedMatModule } from './shared.material.module';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadDialogComponent } from './components/file-upload-dialog/file-upload-dialog.component';
import { RequiredRoleDirective } from './directives/required-role.directive';
import { AuthenticatedDirective } from './directives/authenticated.directive';
import { RouterModule } from '@angular/router';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DocumentService } from './services/document.service';
import { MessageListComponent } from './components/message-list/message-list.component';
import { LoadingBlockComponent } from './components/loading-block/loading-block.component';
import { NgxMaskDirective, NgxMaskPipe, NgxMaskService } from 'ngx-mask';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PhoneInputMaskDirective } from './directives/phone-input-mask.directive';
import { StudentBaseDataComponent } from './components/student/student-base-data/student-base-data.component';
import { StudentDocumentComponent } from './components/student/student-document/student-document.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserNoteListComponent } from './components/user-note-list/user-note-list.component';
import { CardComponent } from './components/cards/simple-card/card.component';
import { GroupDataCardComponent } from './components/cards/group-data-card/group-data-card.component';
import { StudyUnitDataCardComponent } from './components/cards/study-unit-data-card/study-unit-data-card.component';
import { ChartElementComponent } from './components/chart-element/chart-element.component';
import { ChartService } from './services/chart.service';
import { StudentPerfomanceComponent } from './components/student-perfomance/student-perfomance.component';
import { SortingService } from './services/sorting.service';

const components = [
  LongTextComponent,
  DynamicTableComponent,
  FileUploadDialogComponent,
  FileUploadComponent,
  MessageListComponent,
  LoadingBlockComponent,
  StudentBaseDataComponent,
  StudentDocumentComponent,
  UserNoteListComponent,
  CardComponent,
  GroupDataCardComponent,
  StudyUnitDataCardComponent,
  ChartElementComponent,
  StudentPerfomanceComponent,
];

const directives = [
  FileDragNDropDirective,
  RequiredRoleDirective,
  AuthenticatedDirective,
  PhoneMaskDirective,
  PhoneInputMaskDirective,
];

@NgModule({
  declarations: [components, directives],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SharedMatModule,
    RouterModule,
    NgxMaskPipe,
    NgxMaskDirective,
    ReactiveFormsModule,
  ],
  exports: [components, directives, NgxMaskPipe, NgxMaskDirective],
  providers: [DocumentService, NgxMaskService, ChartService, SortingService],
})
export class SharedModule {}
