export function notNull<T>(item: T | null): item is T {
  return item !== null;
}

export function notUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

export function notNullOrUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
