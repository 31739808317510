<div *ngIf="data === null" class="spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>
<table
  *ngIf="data !== null"
  mat-table
  [dataSource]="dataSource"
  [ngClass]="tableClasses"
  matSort>
  <ng-content></ng-content>

  <tr mat-header-row *matHeaderRowDef="displayColumnKeys; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayColumnKeys"
    (click)="rowAction(row)"></tr>

  <ng-container
    *ngFor="let column of columnMapping"
    [matColumnDef]="column.key">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ column.headerLabel || column.key | translate }}
    </th>
    <td mat-cell *matCellDef="let rowItem">
      <ng-template
        #content
        [ngTemplateOutlet]="content"
        let-value="value"
        [ngTemplateOutletContext]="{ value: mappedValue(column, rowItem) }">
        <span *ngIf="typeof(value) === 'Date'">
          {{ value | date : column.dateFormat || 'short' }}
        </span>
        <span *ngIf="typeof(value) === 'string' && column.translate">
          {{ value | translate }}
        </span>
        <span *ngIf="typeof(value) === 'string' && !column.translate">
          {{ value }}
        </span>
        <span *ngIf="typeof(value) === 'number'">
          {{ value }}
        </span>
        <span *ngIf="typeof(value) === 'boolean'"> {{value)} </span>
      </ng-template>
    </td>
  </ng-container>
</table>
