import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, DocumentType } from '@core/services/api.service';
import { DocumentCategory } from '../enums/documentCategory';
import { D5M1ErrorData } from '../../features/management/modules/company/modules/company-edit/interfaces/d5m1-errorData';

@Injectable()
export class DocumentService {
  constructor(private readonly apiService: ApiService) {}

  uploadFile(
    fileToUpload: File,
    type: DocumentType,
    ownerId: string,
    category: DocumentCategory,
    documentId?: string
  ) {
    if (documentId && type === DocumentType.Other) {
      return this.apiService.uploadOtherDocument(documentId, fileToUpload);
    }
    if (category === DocumentCategory.Company) {
      return this.apiService.uploadCompanyDocument(fileToUpload, type, ownerId);
    } else if (category === DocumentCategory.StudentGroup) {
      return this.apiService.uploadLearningGroupDocument(
        fileToUpload,
        type,
        ownerId
      );
    } else if (category === DocumentCategory.Student) {
      return this.apiService.uploadStudentDocument(fileToUpload, type, ownerId);
    } else if (category === DocumentCategory.Course) {
      const id = parseInt(ownerId);
      return this.apiService.uploadCourseDocument(fileToUpload, type, id);
    }
    throw new Error(`Invalid document category ${category}`);
  }

  uploadD5M1(fileToUpload: File, companyId: string) {
    return this.apiService.uploadD5M1(fileToUpload, companyId);
  }

  importCompany(fileToUpload: File) {
    return this.apiService.importCompany(fileToUpload);
  }

  downloadFile(documentId: string) {
    return this.apiService.downloadDocument(documentId);
  }

  getStudentCertificateDetails(documentId: string) {
    return this.apiService.getDocumentDetails(documentId);
  }

  saveFileResponse(response: HttpResponse<Blob>) {
    if (
      response === null ||
      response.body === null ||
      response.headers === null
    )
      return;

    let fileName = 'no-filename';
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    this.saveFile(response.body, fileName);
  }

  saveFile(blobfile: Blob, filename: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blobfile);
    link.download = filename;
    link.click();
  }

  createErrorList(errorResponse: HttpErrorResponse) {
    let errorMessage = '';
    const errors = (errorResponse.error as D5M1ErrorData).importErrors;
    if (!errors) {
      throw errorResponse.error;
    }
    errors.forEach((error, index) => {
      const errorCount = (index + 1).toString();
      errorMessage +=
        '<br>' + errorCount + '. hiba: ' + '<br>' + error.errorText + '<br>';
    });
    return errorMessage;
  }
}
