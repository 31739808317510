import {
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, forkJoin, Observable, throwError } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  retry,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ApiService, LoginDetailsView } from '@core/services/api.service';
import { Store } from '@ngrx/store';
import {
  selectAccessToken,
  selectRefreshToken,
} from '@core/ngrx-store/auth/auth.state';
import {
  Logout,
  SuccessfulAuthentication,
} from '@core/ngrx-store/auth/auth.actions';
import {
  selectUserEmail,
  selectUserLoginRole,
} from '@core/ngrx-store/user/user.state';
import { notNull } from '@core/ngrx-store/extension-methods';
import { environment } from 'src/environments/environment';
import { Role } from '@core/enums/app.role';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  refreshing = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly apiService: ApiService,
    private readonly store: Store
  ) {}

  // Intercept any http request and handle if it returns a 401 error (Unauthorized)
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          !request.url.includes('/login') &&
          error.status === 401
        ) {
          return this.handle401Error(next, request);
        }
        throw error;
      })
    );
  }

  /** Handle not auth error logic. */
  private handle401Error(
    next: HttpHandler,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    if (this.refreshing.value) {
      console.log('Go pending', request.url);
      return this.pendingLogic(next, request);
    }
    this.refreshing.next(true);
    return this.refreshToken().pipe(
      tap(([loginData, role]) => {
        this.store.dispatch(
          SuccessfulAuthentication({ ...loginData, role: role }, false)
        );
      }),
      catchError(_ => {
        this.forcedLogout();
        throw Error('Error.Network.Name.RefreshToken');
      }),
      delay(1000),
      switchMap(() =>
        next.handle(this.apiService.refreshRequest(request)).pipe(
          filter(response => response instanceof HttpResponse),
          retry(environment.api.maxUnauthorizedAttempts),
          tap(() => {
            this.refreshing.next(false);
          }),
          catchError(_ => {
            this.forcedLogout();
            throw Error('Error.Network.Name.Authorization');
          })
        )
      )
    );
  }

  /** Calls refresh-token endpoint and return new login details */
  private refreshToken(): Observable<[LoginDetailsView, Role]> {
    return forkJoin([
      this.store.select(selectRefreshToken).pipe(take(1)),
      this.store.select(selectUserEmail).pipe(take(1)),
    ]).pipe(
      delay(2000),
      switchMap(([refreshToken, userEmail]) =>
        refreshToken && userEmail
          ? forkJoin([
              this.apiService.refreshToken(refreshToken, userEmail),
              this.store
                .select(selectUserLoginRole)
                .pipe(filter(notNull), take(1)),
            ])
          : throwError(() => new Error('Invalid store'))
      ),
      takeUntil(this.getLogout())
    );
  }

  private pendingLogic(next: HttpHandler, request: HttpRequest<unknown>) {
    return this.refreshing.pipe(
      filter(refresh => refresh === false),
      tap(_ => {
        console.log('Resolve pending', request.url);
      }),
      take(1),
      switchMap(_ => next.handle(this.apiService.refreshRequest(request))),
      takeUntil(this.getLogout()),
      catchError(() => EMPTY)
    );
  }

  private forcedLogout() {
    this.store.dispatch(Logout());
    this.refreshing.next(false);
  }

  private getLogout() {
    return this.store
      .select(selectAccessToken)
      .pipe(filter(token => token === null));
  }
}
