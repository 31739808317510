import { createReducer, on } from '@ngrx/store';
import {
  SuccessfulAuthentication,
  ResetAuthentication,
  Authentication,
  FailedAuthentication,
} from './auth.actions';
import { AuthState, defaultAuthState } from './auth.state';

export const AuthReducer = createReducer(
  defaultAuthState,
  on(Authentication, (state, _): AuthState => {
    return {
      ...state,
      pending: true,
    };
  }),
  on(SuccessfulAuthentication, (state, payload): AuthState => {
    return {
      ...state,
      accessToken: payload.data.accessToken,
      refreshToken: payload.data.refreshToken,
      pending: false,
    };
  }),
  on(FailedAuthentication, (state, _): AuthState => {
    return {
      ...state,
      pending: false,
    };
  }),
  on(ResetAuthentication, (state): AuthState => {
    return {
      ...state,
      accessToken: null,
      refreshToken: null,
      pending: false,
    };
  })
);
