import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '@core/services/snackbar.service';

@Component({
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss'],
})
export class FileUploadDialogComponent implements OnInit {
  files: File[] = [];
  acceptedFiles = '';
  uploadDisabled = true;

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly dialogRef: MatDialogRef<FileUploadDialogComponent, File[]>,
    @Inject(MAT_DIALOG_DATA)
    public data: { acceptedFiles: string }
  ) {}

  ngOnInit(): void {
    this.acceptedFiles = this.data ? this.data.acceptedFiles : '';
  }

  confirmUpload() {
    this.dialogRef.close(this.files);
  }

  updateUploadedFiles(value: Array<File>) {
    this.uploadDisabled = false;
    if (!this.data) {
      this.files = value;
      return;
    }

    const acceptedFileTypes = this.acceptedFiles
      .split(',')
      .map(type => type.trim());
    const hasValidFiles =
      value.filter(file =>
        acceptedFileTypes.includes(
          file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        )
      ).length !== 0;

    if (!hasValidFiles) {
      this.uploadDisabled = true;
      this.snackBarService.error(
        'Hibás fájltípus, a megengedett típusok: ' + this.acceptedFiles
      );
      return;
    }

    this.files = value;
  }
}
