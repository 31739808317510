import { Component, Input } from '@angular/core';
import { ChartSize } from '@shared/enums/chart.size';
import { ManabiChartData } from '@shared/interfaces/chartData';

@Component({
  selector: 'study-unit-data-card',
  templateUrl: './study-unit-data-card.component.html',
  styleUrls: ['./study-unit-data-card.component.scss'],
})
export class StudyUnitDataCardComponent {
  @Input() title = '';

  @Input() value = '';

  @Input() chartData: ManabiChartData | null = null;

  chartSize = ChartSize;
}
