<div class="flex bg-white">
  <div class="m-2 w-full">
    <div class="flex flex-col student-perfomance">
      <div class="section-title mr-8">Teljesítmény</div>

      <div class="flex flex-col gap-4">
        <ng-container
          *ngTemplateOutlet="dataSource.data.length > 0 ? table : noData">
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #table>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="module">
      <th class="w-1/3" mat-header-cell *matHeaderCellDef>Modul</th>
      <td class="w-1/3" mat-cell *matCellDef="let result">
        {{ result.moduleTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="quiz">
      <th class="w-1/3" mat-header-cell *matHeaderCellDef>Quiz</th>
      <td class="w-1/3" mat-cell *matCellDef="let result">
        {{ result.quizTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="perfomance">
      <th mat-header-cell *matHeaderCellDef>Teljesítmény</th>
      <td mat-cell *matCellDef="let result">
        {{
          result.resultPoints / result.maxPoints || result.resultInPercentage
            | percent
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Dátum</th>
      <td mat-cell *matCellDef="let result">
        {{ result.completedAt | date }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="paginationOptions"></mat-paginator>
</ng-template>

<ng-template #noData>
  <div class="flex justify-center mb-8 italic text-[#aaa]">
    Nincsen kitöltött quiz
  </div>
</ng-template>
