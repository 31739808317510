<div class="flex flex-col gap-4">
  <form *ngIf="studentBaseDataForm" [formGroup]="studentBaseDataForm">
    <div class="field-group">
      <div class="header">
        <mat-label translate>Student.Label.BaseData</mat-label>
        <mat-divider></mat-divider>
      </div>
      <div class="content">
        <div class="flex">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.FullName</mat-label>
            <input matInput placeholder="Név" formControlName="fullName" />
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.MaidenFullName</mat-label>
            <input
              matInput
              placeholder="Születési név"
              formControlName="maidenFullName" />
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.PlaceOfBirth</mat-label>
            <input
              matInput
              placeholder="Születési hely"
              formControlName="placeOfBirth" />
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field class="date-picker" floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.DateOfBirth</mat-label>
            <input
              matInput
              [matDatepicker]="dateOfBirth"
              placeholder="Születési dátum"
              formControlName="dateOfBirth" />
            <mat-datepicker-toggle matSuffix [for]="dateOfBirth">
              <mat-icon matDatepickerToggleIcon class="calendar-icon">
                <img src="assets/images/icons/calendar.svg" />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dateOfBirth></mat-datepicker>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.MothersMaidenFullName</mat-label>
            <input
              matInput
              placeholder="Anyja neve"
              formControlName="mothersMaidenFullName" />
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.Address</mat-label>
            <input matInput placeholder="Lakcím" formControlName="address" />
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.Taj</mat-label>
            <input matInput placeholder="TAJ szám" formControlName="taj" mask="000-000-000"/>
            <mat-error>
              Formátum: 123-123-123
            </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.TaxIdNumber</mat-label>
            <input
              matInput
              placeholder="Adóazonosító jel"
              formControlName="taxIdNumber" mask="0000000000"/>
              <mat-error>
                Formátum: 0123456789
              </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>
              Student.Label.VocationalTrainingStudentId
            </mat-label>
            <input
              matInput
              placeholder="Oktatási azonostó"
              formControlName="vocationalTrainingStudentId" mask="00000000000"/>
              <mat-error>
                Formátum: 01234567891
              </mat-error>
          </mat-form-field>
        </div>

        <div class="flex">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.IdCardNumber</mat-label>
            <input
              matInput
              placeholder="Személyi igazolvány száma"
              formControlName="idCardNumber" mask="000000SS"/>
              <mat-error>
                Formátum: 123456AB
              </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.Email</mat-label>
            <input matInput placeholder="E-mail címe" formControlName="email" />
            <mat-error>
              E-mail cím formátum nem megfelelő
            </mat-error>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.Phone</mat-label>
            <input
              matInput
              formControlName="phoneNumber"
              placeholder="Telefonszám" prefix="+" mask="00-00-000-0000"/>
              <mat-error>
                Formátum: +36-00-000-0000
              </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="field-group">
      <div class="header">
        <mat-label translate>Student.Label.UserBaseData</mat-label>
        <mat-divider></mat-divider>
      </div>
      <div class="content">
        <div class="flex mat-form-field-width-1">
          <mat-form-field
            floatLabel="always"
            class="highest-qualification"
            appearance="outline">
            <mat-label translate>Student.Label.HighestQualification</mat-label>
            <mat-select formControlName="highestQualification">
              <mat-option
                *ngFor="let highestQualification of highestQualifications"
                [value]="highestQualification.enumValue">
                {{ highestQualification.displayText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex mat-form-field-width-2">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>
              Student.Label.QualificationCertificateNumber
            </mat-label>
            <input
              matInput
              placeholder="Végzettséget tanúsító okirat száma"
              formControlName="qualificationCertificateNumber" />
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.OkjId</mat-label>
            <input matInput placeholder="OKJ száma" formControlName="okjId" mask="0000000"/>
            <mat-error>
              Formátum: 1234567
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="field-group">
      <div class="header">
        <mat-label translate>Student.Label.HasGraduatedHighSchool</mat-label>
        <mat-divider></mat-divider>
      </div>
      <div class="content">
        <div class="flex">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate
              >Student.Label.HasGraduatedHighSchool</mat-label
            >
            <mat-select formControlName="hasGraduatedHighSchool">
              <mat-option [value]="true">
                {{ 'General.Yes' | translate }}
              </mat-option>
              <mat-option translate [value]="false">
                {{ 'General.No' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="date-picker" floatLabel="always" appearance="outline">
            <mat-label translate>
              Student.Label.StartDateOfCurrentEmployment
            </mat-label>
            <input
              matInput
              [matDatepicker]="startDateOfCurrentEmployment"
              placeholder="Munkaviszony kezdete"
              formControlName="startDateOfCurrentEmployment" />
            <mat-datepicker-toggle
              matSuffix
              [for]="startDateOfCurrentEmployment">
              <mat-icon matDatepickerToggleIcon class="calendar-icon">
                <img src="assets/images/icons/calendar.svg" />
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startDateOfCurrentEmployment></mat-datepicker>
          </mat-form-field>

          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.JobTitle</mat-label>
            <input
              matInput
              placeholder="Munkaköre"
              formControlName="jobTitle" />
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="field-group">
      <div class="header">
        <mat-label translate>Student.Label.Note</mat-label>
        <mat-divider></mat-divider>
      </div>
      <div class="content">
        <div class="flex">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label translate>Student.Label.Note</mat-label>
            <textarea
              formControlName="note"
              matInput
              cdkTextareaAutosize
              cdkAutosizeMinRows="4"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
