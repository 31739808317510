import { Role } from '@core/enums/app.role';

export const CommunicationRoles = {
  CommunicationMenuRoles: [
    Role.GoodLifeAdmin,
    Role.TrainingCenterAdmin,
    Role.CompanyAdmin,
    Role.Student,
  ],
  SendMessageRole: [Role.GoodLifeAdmin],

  ReadMessageRole: [
    Role.GoodLifeAdmin,
    Role.TrainingCenterAdmin,
    Role.CompanyAdmin,
    Role.Student,
  ],

  CreateTemplateRoles: [Role.GoodLifeAdmin],
};
