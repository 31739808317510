import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  pending: boolean;
}

export const defaultAuthState: AuthState = {
  accessToken: null,
  refreshToken: null,
  pending: false,
};

const selectAuthState = createFeatureSelector<AuthState>('AuthFeature');

export const selectAccessToken = createSelector(
  selectAuthState,
  state => state.accessToken
);

export const selectAuthStatus = createSelector(
  selectAuthState,
  state => state.accessToken !== null
);

export const selectRefreshToken = createSelector(
  selectAuthState,
  state => state.refreshToken
);

export const selectAuthPendingStatus = createSelector(
  selectAuthState,
  state => state.pending
);
