import {
  Directive,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { selectUserLoginRole } from '@core/ngrx-store/user/user.state';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[mustBeAuthenticated]',
})
export class AuthenticatedDirective implements OnInit, OnDestroy {
  isVisible = false;
  routerSubscription$: any;

  private readonly destroy$ = new Subject<void>();
  /**
   * @param {ViewContainerRef} viewContainerRef
   * The location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef
   * The templateRef to be potentially rendered
   * @param {Store} store
   * Will give us access to the roles a user has
   */
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<any>,
    private readonly store: Store
  ) {}

  ngOnInit() {
    this.checkAuthentication();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkAuthentication() {
    this.store
      .select(selectUserLoginRole)
      .pipe(takeUntil(this.destroy$))
      .subscribe(role => {
        if (role === null) {
          this.isVisible = false;
          this.viewContainerRef.clear();
          return;
        }
        // If the user has the role needed to
        // render this component we can add it
        if (role) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }
}
