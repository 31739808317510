import { Injectable } from '@angular/core';
import {
  CourseModuleListView,
  ELearningStudyUnitListView,
  StudyUnitListView,
} from '@core/services/api.service';

@Injectable()
export class SortingService {
  orderStudyUnits(
    itemX: StudyUnitListView | ELearningStudyUnitListView,
    itemY: StudyUnitListView | ELearningStudyUnitListView
  ): number {
    return itemX.isModulFinalExam === itemY.isModulFinalExam
      ? 0
      : itemX.isModulFinalExam
      ? 1
      : -1;
  }

  orderCourseModules(
    itemX: CourseModuleListView,
    itemY: CourseModuleListView
  ): number {
    return itemX.isInitialMeasurement === itemY.isInitialMeasurement
      ? 0
      : itemX.isInitialMeasurement
      ? -1
      : 1;
  }
}
