<div class="header">
  <div class="logo cursor-pointer" [routerLink]="'/'">
    <img src="assets/images/logo/manabi_logo.svg" />
  </div>
  <div class="header-items">
    <div class="notification-item cursor-pointer" (click)="gotToMessages()">
      <mat-icon
        matBadge="0"
        matBadgeSize="small"
        matBadgePosition="before"
        [matBadgeHidden]="(hasNotification$ | async) !== true">
        <img width="30px" src="assets/images/icons/notification.svg" />
      </mat-icon>
    </div>
    <div class="notification-item">
      <mat-icon>
        <img
          class="primary-filter"
          width="18px"
          src="assets/images/icons/account.svg" />
      </mat-icon>
    </div>
    <div class="flex flex-col">
      <span class="text-primary font-bold">{{ userName$ | async }}</span>
      <span
        class="text-primary"
        style="font-size: smaller"
        *ngIf="role$ | async as role">
        {{ 'Enum.Role.' + role | translate }}
      </span>
    </div>
    <div class="header-select-container">
      <mat-select class="user-item" panelClass="user-item-select-panel">
        <mat-option [routerLink]="'/profile'">Fiók</mat-option>
        <mat-option (click)="logout()">Kijelentkezés</mat-option>
      </mat-select>
    </div>
  </div>
</div>
