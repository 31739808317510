import { Observable, tap } from 'rxjs';
import {
  ApiService,
  HighestQualificationListItem,
  NoteData,
  StudentData,
  StudentDetailsView,
  StudentListView,
  StudentsInCompanyListView,
  StudentStatusType,
} from '@core/services/api.service';
import { Injectable } from '@angular/core';
import { DocumentValidationData } from '../interfaces/documentValidationData';
import { SetStudent } from '../../../ngrx-store/company-edit.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class StudentService {
  constructor(
    private readonly apiService: ApiService,
    private readonly store: Store
  ) {}

  get(
    companyId: string,
    studentGroupId: string,
    studentId: string
  ): Observable<StudentDetailsView> {
    return this.apiService.getStudentById(studentId, studentGroupId, companyId);
  }

  getAllByStudentGroup(
    companyId: string,
    studentGroupId: string
  ): Observable<StudentListView[]> {
    return this.apiService.getStudentsByLearningGroup(
      companyId,
      studentGroupId
    );
  }

  getAllByCompany(companyId: string): Observable<StudentsInCompanyListView[]> {
    return this.apiService.getStudentsByCompany(companyId);
  }

  add(
    companyId: string,
    studentGroupId: string,
    student: StudentData
  ): Observable<StudentDetailsView> {
    return this.apiService.postStudent(studentGroupId, companyId, student);
  }

  update(
    companyId: string,
    studentGroupId: string,
    studentId: string,
    student: StudentData
  ) {
    return this.apiService
      .putStudent(studentId, studentGroupId, companyId, student)
      .pipe(tap(student => this.store.dispatch(SetStudent(student))));
  }

  inactivateStudent(
    companyId: string,
    studentGroupId: string,
    studentId: string,
    note: NoteData
  ) {
    return this.apiService.postStudentInactiveState(
      studentId,
      studentGroupId,
      companyId,
      note
    );
  }

  getHighestQualifications(): Observable<HighestQualificationListItem[]> {
    return this.apiService.getHighestQualificationValues();
  }

  validateDocument(data: DocumentValidationData) {
    if (
      data.isApproved &&
      data.status === StudentStatusType.WaitingForGoodLifeAdminCheck
    ) {
      return this.apiService.approveDocumentAsGLAdmin(
        data.documentId,
        data.text
      );
    } else if (
      data.isApproved &&
      data.status === StudentStatusType.WaitingForTrainingCenterAdminCheck
    ) {
      return this.apiService.approveDocumentAsTrainingCenterAdmin(
        data.documentId,
        data.text
      );
    } else if (
      !data.isApproved &&
      data.status === StudentStatusType.WaitingForGoodLifeAdminCheck
    ) {
      return this.apiService.rejectDocumentAsGLAdmin(
        data.documentId,
        data.text
      );
    } else {
      return this.apiService.rejectDocumentAsTrainingCenterAdmin(
        data.documentId,
        data.text
      );
    }
  }

  getStudentOwnData(): Observable<StudentDetailsView> {
    return this.apiService.getStudentOwnData();
  }
}
