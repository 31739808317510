import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoteListView, StatusAction } from '@core/services/api.service';

@Component({
  selector: 'certificate-details-dialog',
  templateUrl: './certificate-details-dialog.component.html',
  styleUrls: ['./certificate-details-dialog.component.scss'],
})
export class CertificateDetailsDialogComponent {
  panelOpenState = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NoteListView[]
  ) {}

  getIcon(action: StatusAction) {
    return action === StatusAction.CertificateByGoodLifeAdminRejected ||
      action === StatusAction.CertificateByLearningCenterAdminRejected
      ? 'assets/images/icons/report_orange.svg'
      : 'assets/images/icons/check_green.svg';
  }
}
