import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  NgZone,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import {
  CommonModule,
  DATE_PIPE_DEFAULT_OPTIONS,
  registerLocaleData,
} from '@angular/common';
import { CoreModule } from './core/core.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppInitializerFactory } from 'src/app/app.initializer.factory';
import { GlobalErrorHandler } from '@core/interceptors/global.error.interceptor';
import { DialogService } from '@core/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { HttpErrorInterceptor } from '@core/interceptors/http.error.interceptor';
import { ApiService } from '@core/services/api.service';
import localeHu from '@angular/common/locales/hu';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';

const DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY. MMMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY. MMMM',
  },
};
registerLocaleData(localeHu);
@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    AppRoutingModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatMomentDateModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [DialogService, TranslateService, NgZone],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      deps: [ApiService, Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService, DateAdapter, Store, ApiService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'hu' },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'shortDate' },
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
