import { Role } from '@core/enums/app.role';
import { UserOwnDetailsView } from '@core/services/api.service';
import { createAction, props } from '@ngrx/store';

export const SetUserData = createAction(
  '[User data] Set user data',
  props<{ data: UserOwnDetailsView | null }>()
);

export const SetUserLoginRole = createAction(
  '[User role] Set user role',
  props<{ role: Role | null }>()
);

export const SetUserFullName = createAction(
  '[User data] Set user full name',
  props<{ name: string }>()
);

export const SetIsNotificationCleared = createAction(
  '[User data] Set isNotificationCleared',
  (isNotificationCleared: boolean) => ({ isNotificationCleared })
);
