import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MessageListView } from '@core/services/api.service';
import { PaginationOptions } from '@shared/configs/pagination-option';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent implements OnInit, OnChanges {
  @Input() messageList: MessageListView[] | null = null;
  @Input() selectedMessage: MessageListView | null = null;
  // Whether the list is incoming or outgoing (displays messages differently)
  @Input() incoming = false;
  @Input() shortList = false;
  @Output() messageSelectedEvent = new EventEmitter<MessageListView>();

  currentMessagesToShow: MessageListView[] = [];
  messageCount = 0;
  paginationOptions: number[] = [];

  pageSize = 5;

  ngOnInit(): void {
    this.paginationOptions = this.shortList ? [2, 5, 10] : PaginationOptions;
    this.pageSize = this.shortList ? 2 : 5;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('messageList') && this.messageList) {
      this._updateList(this.messageList, this.shortList);
    }
  }

  onPageChange($event: PageEvent) {
    if (!this.messageList) {
      return;
    }
    this.currentMessagesToShow = this.messageList.slice(
      $event.pageIndex * $event.pageSize,
      $event.pageIndex * $event.pageSize + $event.pageSize
    );
  }

  messageClick(messageContent: MessageListView) {
    this.messageSelectedEvent.emit(messageContent);
  }

  private _updateList(messages: MessageListView[], isStudentHome: boolean) {
    const maxMessages = isStudentHome ? 2 : 5;
    this.messageCount = messages.length;
    this.currentMessagesToShow = messages.slice(0, maxMessages);
  }
}
