import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { LocalizedMatTableDataSource } from './localized-mat-table-data-source';

@Directive()
export class BaseListComponent<T> implements OnChanges, OnDestroy {
  @Input() data: T[] | null = null;
  @Output() edit: EventEmitter<T> = new EventEmitter<T>();
  @Output() delete: EventEmitter<T> = new EventEmitter<T>();

  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  dataSource: LocalizedMatTableDataSource<T> =
    new LocalizedMatTableDataSource<T>();
  displayedColumns: string[] = [];
  loaded = false;

  protected readonly destroy$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      this.loaded = false;
      if (this.data) {
        this.dataSource.data = this.data;
        this.loaded = true;
      }
    }
  }

  editItem(item: T) {
    this.edit.next(item);
  }

  deleteItem(item: T) {
    this.delete.next(item);
  }

  applyFilter(inputValue: Event) {
    const filterValue = (inputValue.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
