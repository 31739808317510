import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'long-text',
  templateUrl: './long-text.component.html',
  styleUrls: ['./long-text.component.scss'],
})
export class LongTextComponent implements OnChanges {
  @Input() text = '';
  @Input() textClasses = ['long-text'];
  @Input() max = 200;
  @Input() more = this.translate.instant('General.LongText.More') as string;
  @Input() moreClasses = ['more'];
  @Input() less = this.translate.instant('General.LongText.Less') as string;
  @Input() lessClasses = ['less'];
  @Input() shrinkable = true;
  @Input() dotSeperator = true;
  @Output() fullDisplayed = new EventEmitter<boolean>(false);

  public displayText = '';

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.displayText = this.cut(this.text, this.max, this.dotSeperator);
  }

  showMore() {
    this.displayText = this.text;
    this.fullDisplayed.next(true);
  }

  showLess() {
    this.displayText = this.cut(this.text, this.max, this.dotSeperator);
    this.fullDisplayed.next(false);
  }

  private cut(text: string, max: number, dotSeperator: boolean) {
    let displayText = text;
    if (displayText.length > max) {
      displayText = text.substring(0, max);
      if (dotSeperator) {
        const lastDot = displayText.lastIndexOf('.');
        displayText = displayText.substring(0, lastDot + 1);
      }
      this.fullDisplayed.next(false);
    } else {
      this.shrinkable = false;
      this.fullDisplayed.next(true);
    }
    return displayText;
  }
}
