<div
  class="dropzone w-full h-full"
  fileDragDrop
  [multiple]="multiple"
  (filesChangeEmiter)="onFileChange($event)">
  <label class="text-wrapper w-full h-full" for="file">
    <div class="centered h-full justify-center">
      <input
        type="file"
        name="file"
        id="file"
        [accept]="acceptedFiles"
        (change)="onFileChangeClick($event)"
        [disabled]="disabled"
        [multiple]="multiple" />
      <div
        class="flex flex-col items-center text-center"
        [innerHTML]="label"></div>
      <div>
        <img width="30px" src="assets/images/icons/cloud upload.svg" />
      </div>
      <div class="flex" *ngFor="let file of files">
        <span>{{ file.name }} feltöltés</span>
      </div>
    </div>
  </label>
</div>
