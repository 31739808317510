import { Component } from '@angular/core';
import { NoteListView } from '@core/services/api.service';
import { BaseListComponent } from '@shared/classes/base.list.component';

@Component({
  selector: 'user-note-list',
  templateUrl: './user-note-list.component.html',
  styleUrls: ['./user-note-list.component.scss'],
})
export class UserNoteListComponent extends BaseListComponent<NoteListView> {
  override displayedColumns: string[] = [
    'text',
    'action',
    'createdBy',
    'createdAt',
  ];
}
