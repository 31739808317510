import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  Authentication,
  Logout,
  ResetAuthentication,
  SuccessfulAuthentication,
  ChooseRole,
  FailedAuthentication,
} from './auth.actions';
import { ApiService } from '@core/services/api.service';
import { SetUserData, SetUserLoginRole } from '../user/user.actions';
import { Store } from '@ngrx/store';
import { Role } from '@core/enums/app.role';
import { UtilService } from '@core/services/util.service';
import { of } from 'rxjs';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => {
    return this.actions.pipe(
      ofType(Authentication),
      switchMap(action =>
        this.apiService.login(action.data).pipe(
          map(response => {
            if (
              !response.isAdminStatusActive &&
              !response.isStudentStatusActive
            ) {
              return FailedAuthentication({
                error: new Error('A felhasználó inaktiválva lett!'),
              });
            }
            const roles = response.userRoles as Role[];
            if (roles.length > 1) {
              return ChooseRole({
                data: response,
              });
            } else {
              return SuccessfulAuthentication({ ...response, role: roles[0] });
            }
          }),
          catchError((err: Error) => {
            return of(FailedAuthentication({ error: err }));
          })
        )
      )
    );
  });

  failedAuthentication$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(FailedAuthentication),
        tap(action => {
          throw action.error;
        })
      );
    },
    { dispatch: false }
  );

  authenticationChooseRole$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ChooseRole),
      switchMap(action => {
        const dialog = this.util.openRoleSelectDialog(action.data);
        return dialog.pipe(
          map(role => {
            if (role) {
              return SuccessfulAuthentication({ ...action.data, role: role });
            }
            return FailedAuthentication({
              error: new Error('Nincs szerep kiválasztva!'),
            });
          })
        );
      })
    );
  });

  authenticationSetToken$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(SuccessfulAuthentication),
        map(action => {
          this.apiService.setAccessToken(action.data.accessToken);
        })
      );
    },
    { dispatch: false }
  );

  authenticationSetLoginRole$ = createEffect(() => {
    return this.actions.pipe(
      ofType(SuccessfulAuthentication),
      map(action => {
        if (action.navigate) {
          this.util.navigateToDefault();
        }
        return SetUserLoginRole({ role: action.data.role });
      })
    );
  });

  authenticationSetUserData$ = createEffect(() => {
    return this.actions.pipe(
      ofType(SuccessfulAuthentication),
      switchMap(_ => {
        return this.apiService
          .getUserSelf()
          .pipe(map(result => SetUserData({ data: result })));
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions.pipe(
      ofType(Logout),
      map(_ => {
        return ResetAuthentication();
      })
    );
  });

  resetAccessToken$ = createEffect(
    () => {
      return this.actions.pipe(
        ofType(ResetAuthentication),
        map(_ => {
          this.apiService.setAccessToken(null);
        })
      );
    },
    { dispatch: false }
  );

  resetRoles$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ResetAuthentication),
      map(_ => {
        this.util.navigateToLogin();
        return SetUserLoginRole({ role: null });
      })
    );
  });

  resetUserData$ = createEffect(() => {
    return this.actions.pipe(
      ofType(ResetAuthentication),
      map(_ => {
        return SetUserData({ data: null });
      })
    );
  });

  constructor(
    private readonly actions: Actions,
    private readonly apiService: ApiService,
    private readonly util: UtilService,
    private readonly store: Store
  ) {}
}
