import { Component } from '@angular/core';
import {
  QuizResultDashboardView,
  QuizResultListView,
} from '@core/services/api.service';
import { BaseListComponent } from '@shared/classes/base.list.component';
import { PaginationOptions } from '@shared/configs/pagination-option';

@Component({
  selector: 'student-perfomance',
  templateUrl: './student-perfomance.component.html',
  styleUrls: ['./student-perfomance.component.scss'],
})
export class StudentPerfomanceComponent extends BaseListComponent<
  QuizResultListView | QuizResultDashboardView
> {
  override displayedColumns: string[] = [
    'module',
    'quiz',
    'perfomance',
    'date',
  ];

  paginationOptions: number[] = PaginationOptions;
}
