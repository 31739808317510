import { CommunicationRoles } from '@core/utilities/required-roles/communication.required-roles';
import { CompanyRoles } from '@core/utilities/required-roles/company.required-roles';
import { CourseManagementRoles } from '@core/utilities/required-roles/course.required-roles';
import { DashboardRoles } from '@core/utilities/required-roles/dashboard.required-roles';
import { ElearningRoles } from '@core/utilities/required-roles/elearning.required-roles';
import { HomeRoles } from '@core/utilities/required-roles/home.required-roles';
import { UserManagementRoles } from '@core/utilities/required-roles/user.required-roles';

export const navbarData = [
  {
    routeLink: '/home',
    icon: 'assets/images/icons/home.svg',
    label: 'Home',
    requiredRoles: HomeRoles,
  },
  {
    routeLink: '/management/company',
    icon: 'assets/images/icons/company.svg',
    label: 'CompanyManager',
    requiredRoles: CompanyRoles.CompanyDetailsRoles,
  },
  {
    routeLink: '/management/communication',
    icon: 'assets/images/icons/communication.svg',
    label: 'Communication',
    requiredRoles: CommunicationRoles.CommunicationMenuRoles,
  },
  {
    routeLink: '/management/user',
    icon: 'assets/images/icons/user.svg',
    label: 'User',
    requiredRoles: UserManagementRoles,
  },
  {
    routeLink: '/management/course',
    icon: 'assets/images/icons/course.svg',
    label: 'Course',
    requiredRoles: CourseManagementRoles,
  },
  {
    routeLink: '/elearning',
    icon: 'assets/images/icons/dashboard.svg',
    label: 'Elearning',
    requiredRoles: ElearningRoles,
  },
  {
    routeLink: '/dashboard',
    icon: 'assets/images/icons/global_black.svg',
    label: 'Dashboard',
    requiredRoles: DashboardRoles,
  },
];
