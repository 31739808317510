import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { AuthReducer } from './auth/auth.reducer';
import { AuthState } from './auth/auth.state';
import { EnvironmentReducer } from './environment/environment.reducer';
import { EnvironmentState } from './environment/environment.state';
import { UserDataReducer } from './user/user.reducer';
import { UserDataState } from './user/user.state';

// expand states type as needed
export type States = AuthState | EnvironmentState | UserDataState;

// expand features as needed
export const localStorageSyncReducers = {
  AuthFeature: AuthReducer,
  EnvironmentFeature: EnvironmentReducer,
  UserDataFeature: UserDataReducer,
};

type ActionReducerUnion<T> = T extends infer A ? ActionReducer<A> : never;
export type ReducerUnion = ActionReducerUnion<States>;

export function localStorageSyncReducer<TState>(
  reducer: ActionReducer<TState>
): ActionReducer<TState> {
  return localStorageSync({
    keys: Object.keys(localStorageSyncReducers),
    rehydrate: true,
    storageKeySerializer: key => `${environment.app.name}/${key}`,
  })(reducer);
}
export const metaReducers = [localStorageSyncReducer];
