import { Role } from '@core/enums/app.role';

export const CompanyRoles = {
  CompanyAddRoles: [Role.GoodLifeAdmin],

  CompanyDeleteRoles: [Role.GoodLifeAdmin],

  CompanyDetailsRoles: [
    Role.GoodLifeAdmin,
    Role.CompanyAdmin,
    Role.TrainingCenterAdmin,
  ],

  CompanyEditRoles: [Role.GoodLifeAdmin, Role.CompanyAdmin],

  ExportRoles: [Role.GoodLifeAdmin, Role.CompanyAdmin],

  CompanyAdminEditRoles: [Role.GoodLifeAdmin],

  CompanyLocationEditRoles: [Role.GoodLifeAdmin, Role.CompanyAdmin],

  CompanyDocumentRoles: [Role.GoodLifeAdmin, Role.CompanyAdmin],
};
