import { createAction, props } from '@ngrx/store';

export const SetLanguage = createAction(
  '[Environment] Language set',
  props<{ language: string }>()
);

export const SetLastEmail = createAction(
  '[Environment] Last email set',
  props<{ email: string }>()
);
