<h1 mat-dialog-title>
  <ng-container *ngIf="data.title || data.titleRef; else noContent">
    <ng-container
      [ngTemplateOutlet]="data.titleRef ? data.titleRef : textContent"
      [ngTemplateOutletContext]="{ $implicit: data.title }">
    </ng-container>
  </ng-container>
</h1>

<div mat-dialog-content>
  <ng-container *ngIf="data.content || data.contentRef; else noContent">
    <ng-container
      [ngTemplateOutlet]="data.contentRef ? data.contentRef : textContent"
      [ngTemplateOutletContext]="{ $implicit: data.content }">
    </ng-container>
  </ng-container>
</div>

<div mat-dialog-actions></div>

<ng-template #noContent> --- </ng-template>

<ng-template #textContent let-content>
  <span [innerHTML]="content"></span>
</ng-template>
