import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { selectUserLoginRole } from '@core/ngrx-store/user/user.state';
import { UtilService } from '@core/services/util.service';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

export function NotAuthenticatedGuard(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const util: UtilService = inject(UtilService);

    return store.select(selectUserLoginRole).pipe(
      take(1),
      map(role => {
        const authenticated = role !== null;
        if (role) {
          util.navigateToDefault();
        }
        return !authenticated;
      })
    );
  };
}
