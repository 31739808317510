import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Role } from '@core/enums/app.role';
import { notNull } from '@core/ngrx-store/extension-methods';
import { selectUserLoginRole } from '@core/ngrx-store/user/user.state';
import { UtilService } from '@core/services/util.service';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';

export function RoleGuard(requiredRoles: Role[]): CanActivateFn {
  return () => {
    const store = inject(Store);
    const util: UtilService = inject(UtilService);

    return store.select(selectUserLoginRole).pipe(
      filter(notNull),
      map(role => {
        if (util.checkRole(role, requiredRoles)) {
          return true;
        } else {
          util.navigateToDefault();
          return false;
        }
      })
    );
  };
}
