import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { SetIsNotificationCleared, SetUserData } from '../user/user.actions';
import { SetLastEmail } from '../environment/environment.actions';
import { filter, map } from 'rxjs';
import { notNull } from '../extension-methods';
import { Logout } from '../auth/auth.actions';

@Injectable()
export class UserEffects {
  setUserData$ = createEffect(() => {
    return this.actions.pipe(
      ofType(SetUserData),
      map(action => action.data),
      filter(notNull),
      map(data => {
        return SetLastEmail({ email: data.email });
      })
    );
  });

  resetIsNotificationCleared$ = createEffect(() => {
    return this.actions.pipe(
      ofType(Logout),
      map(_ => {
        return SetIsNotificationCleared(false);
      })
    );
  });

  constructor(private readonly actions: Actions) {}
}
