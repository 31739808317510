import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartSize } from '@shared/enums/chart.size';
import { ManabiChartData } from '@shared/interfaces/chartData';
import { ChartService } from '@shared/services/chart.service';

@Component({
  selector: 'chart-element',
  templateUrl: './chart-element.component.html',
  styleUrls: ['./chart-element.component.scss'],
})
export class ChartElementComponent implements AfterViewInit, OnChanges {
  @Input() chartData: ManabiChartData | null = null;

  @Input() size: ChartSize | null = null;

  @ViewChild('chart')
  private readonly chartRef!: ElementRef<HTMLCanvasElement>;

  chartSize = ChartSize;

  private viewInitialized = false;

  constructor(private readonly chartService: ChartService) {}

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    if (this.chartData) {
      this.createChart(this.chartData);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('chartData') &&
      this.chartData &&
      this.viewInitialized
    ) {
      this.createChart(this.chartData);
    }
  }

  createChart(chartData: ManabiChartData): void {
    this.chartService.createChart(
      this.chartRef.nativeElement,
      chartData.completed,
      chartData.remaining,
      chartData.firstLine,
      chartData.secondLine
    );
  }
}
