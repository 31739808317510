<div class="flex flex-col gap-4">
  <ng-container *ngTemplateOutlet="loaded ? list : loading"> </ng-container>
</div>

<ng-template #list>
  <table
    *ngIf="dataSource.data.length > 0"
    mat-table
    [dataSource]="dataSource"
    matSort>
    <ng-container matColumnDef="text">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Student.Label.Note' | translate }}
      </th>
      <td mat-cell *matCellDef="let item">{{ item.text }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Student.Label.Action' | translate }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ 'UserManagement.StatusAction.' + item.action | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Student.Label.CreatedBy' | translate }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.createdBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'Student.Label.CreatedAt' | translate }}
      </th>
      <td mat-cell *matCellDef="let item">
        {{ item.createdAt | date }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="table-row"
      mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-template>

<ng-template #loading>
  <div class="spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
