import { createReducer, on } from '@ngrx/store';
import {
  SetIsNotificationCleared,
  SetUserData,
  SetUserLoginRole,
} from './user.actions';
import { defaultUserDataState, UserDataState } from './user.state';

export const UserDataReducer = createReducer(
  defaultUserDataState,
  on(SetUserData, (state, payload): UserDataState => {
    if (payload.data !== null) {
      return { ...state, ...payload.data };
    } else {
      return {
        ...state,
        email: null,
        fullName: null,
        phoneNumber: null,
      };
    }
  }),
  on(SetUserLoginRole, (state, payload): UserDataState => {
    if (payload.role !== null) {
      return { ...state, loginRole: payload.role };
    } else {
      return {
        ...state,
        loginRole: null,
      };
    }
  }),
  on(SetIsNotificationCleared, (state, payload): UserDataState => {
    return {
      ...state,
      isNotificationCleared: payload.isNotificationCleared,
    };
  })
);
