import { NgModule } from '@angular/core';
import { SnackBarComponent } from './components/snackbar/snackbar.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { CoreMaterialModule } from './core.material.module';
import { SnackBarService } from './services/snackbar.service';
import { DialogService } from './services/dialog.service';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateLoaderFactory } from './factories/translate.loader.factory';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { API_URL } from './services/api.service';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './factories/date.adapter';
import {
  localStorageSyncReducers,
  metaReducers,
} from './ngrx-store/local-storage-syncer';
import { SharedModule } from '@shared/shared.module';
import { TermsOfUsePrivacyComponent } from './components/terms-of-use-privacy/terms-of-use-privacy.component';
import { FeatureWrapperComponent } from './components/feature-wrapper/feature-wrapper.component';
import { UtilService } from './services/util.service';
import { CustomPaginatorIntl } from './factories/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './ngrx-store/user/user.effects';
import { AuthEffects } from './ngrx-store/auth/auth.effects';

const components = [
  SnackBarComponent,
  DialogComponent,
  SideMenuComponent,
  HeaderComponent,
  TermsOfUsePrivacyComponent,
  FeatureWrapperComponent,
];

@NgModule({
  declarations: [components],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot(localStorageSyncReducers, { metaReducers }),
    StoreModule.forFeature('router', routerReducer),
    EffectsModule.forRoot([AuthEffects, UserEffects]),
    QuillModule.forRoot({
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike']],
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    CommonModule,
    RouterModule,
    CoreMaterialModule,
    MatNativeDateModule,
    SharedModule,
  ],
  exports: [components, CoreMaterialModule],
  providers: [
    provideEnvironmentNgxMask(),
    DialogService,
    SnackBarService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: API_URL, useValue: environment.api.url },
    UtilService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginatorIntl,
    },
  ],
})
export class CoreModule {}
