<div class="flex flex-col gap-4">
  <ng-container *ngTemplateOutlet="loaded ? list : list"> </ng-container>
</div>
<ng-template #list>
  <div class="document-table">
    <table
      *ngIf="dataSource.data.length > 0"
      mat-table
      [dataSource]="dataSource"
      matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.Document' | translate }}
        </th>
        <td class="first-column" mat-cell *matCellDef="let data">
          <div class="flex">
            <img
              *ngIf="data.type !== typeValidating"
              class="secondary-filter"
              width="24px"
              src="assets/images/icons/dokumentumtar.svg"
              alt=""
              title="Bizonyítvány részletek"
              (click)="openCertificateDetailsDialog(data)" />
            <div *ngIf="data.type === typeValidating">
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
            <span class="self-center ml-2">{{
              data.type === type.Other && data.id !== ''
                ? data.name
                : ('Document.Name.' + data.type | translate)
            }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="validatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.ValidatedBy' | translate }}
        </th>
        <td mat-cell *matCellDef="let data">
          {{ data.validatedBy ? data.validatedBy : ' - ' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="validatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.ValidatedAt' | translate }}
        </th>
        <td mat-cell *matCellDef="let data">
          {{
            data.validatedAt === null ||
            data.validatedAt?.toString() === '0001-01-01T00:00:00'
              ? ' - '
              : (data.validatedAt | date)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.CreatedBy' | translate }}
        </th>
        <td mat-cell *matCellDef="let data">{{ data.createdBy }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.CreatedAt' | translate }}
        </th>
        <td mat-cell *matCellDef="let data">
          {{ data.createdAt === null ? ' - ' : (data.createdAt | date) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.Status' | translate }}
        </th>
        <td mat-cell *matCellDef="let data">
          <div class="flex" *ngIf="data.status === status.WaitingForUpload">
            <img
              width="30px"
              src="assets/images/icons/report_orange.svg"
              alt="" />
            <span class="self-center ml-2">{{
              'Document.Status.WaitingForUpload' | translate
            }}</span>
          </div>
          <div class="flex" *ngIf="data.status === status.Approved">
            <img
              width="30px"
              src="assets/images/icons/check_green.svg"
              alt="" />
            <span class="self-center ml-2">{{
              'Document.Status.Uploaded' | translate
            }}</span>
          </div>
          <div class="flex" *ngIf="data.status === status.Rejected">
            <img
              width="30px"
              src="assets/images/icons/report_orange.svg"
              alt="" />
            <span class="self-center ml-2">{{
              'Document.Status.Rejected' | translate
            }}</span>
          </div>
          <div
            class="flex"
            *ngIf="data.status === status.WaitingForGoodLifeAdminCheck">
            <img
              width="30px"
              src="assets/images/icons/cancel_yellow.svg"
              alt="" />
            <span class="self-center ml-2">{{
              'Document.Status.WaitingForGoodLifeAdminCheck' | translate
            }}</span>
          </div>
          <div
            class="flex"
            *ngIf="data.status === status.WaitingForTrainingCenterAdminCheck">
            <img
              width="30px"
              src="assets/images/icons/cancel_yellow.svg"
              alt="" />
            <span class="self-center ml-2">{{
              'Document.Status.WaitingForTrainingCenterAdminCheck' | translate
            }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="upload">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Document.Header.Upload' | translate }}
        </th>
        <td class="cursor-pointer" mat-cell *matCellDef="let data">
          <div
            *ngIf="
              studentId &&
              (data.type !== typeUploading || selectedId !== data.id)
            "
            (click)="openDialog(data, studentId)">
            <img
              [ngClass]="
                data.type === type.QualificationCertificate &&
                data.status !== status.WaitingForUpload &&
                data.status !== status.Rejected
                  ? 'grey-filter'
                  : 'primary-filter'
              "
              width="30px"
              src="assets/images/icons/cloud upload black.svg" />
          </div>
          <div *ngIf="data.type === typeUploading && selectedId === data.id">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="download">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'Document.Header.Download' | translate }}
        </th>
        <td class="cursor-pointer" mat-cell *matCellDef="let data">
          <div
            *ngIf="data.type !== typeDownloading || selectedId !== data.id"
            (click)="downloadFile(data)">
            <img
              [ngClass]="
                data.status === status.WaitingForUpload
                  ? 'grey-filter'
                  : 'primary-filter'
              "
              width="28px"
              src="assets/images/icons/cloud download.svg" />
          </div>
          <div *ngIf="data.type === typeDownloading && selectedId === data.id">
            <mat-spinner [diameter]="30"></mat-spinner>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="validationNeeded">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell class="last-column" *matCellDef="let data">
          <div
            *ngIf="
              data.validationNeeded &&
              ((data.status === status.WaitingForGoodLifeAdminCheck &&
                role === userRole.GoodLifeAdmin) ||
                (data.status === status.WaitingForTrainingCenterAdminCheck &&
                  role === userRole.TrainingCenterAdmin)) &&
              !isStudent
            ">
            <div class="validation">
              <mat-select>
                <mat-option (click)="documentValidation(true, data)">
                  Elfogad
                </mat-option>
                <mat-option (click)="documentValidation(false, data)">
                  Elutasít
                </mat-option>
              </mat-select>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
          validaton:
            row.status === status.WaitingForGoodLifeAdminCheck ||
            row.status === status.WaitingForTrainingCenterAdminCheck
        }"></tr>
    </table>
  </div>
</ng-template>

<ng-template #loading>
  <div class="spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
