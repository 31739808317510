import { Component } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
import { navbarData } from './nav-data';
import { UtilService } from '@core/services/util.service';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ width: 0 }),
        animate('350ms', style({ width: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ width: '*' }),
        animate('350ms', style({ width: 0, opacity: 0 })),
      ]),
    ]),
    trigger('offset', [
      state(
        'open',
        style({
          transform: 'translate(50%) rotate(180deg)',
          offset: '1',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translate(50%) rotate(0deg)',
          offset: '0',
        })
      ),
      transition('open => closed', [animate('400ms')]),
      transition('closed => open', [animate('345ms')]),
    ]),
  ],
})
export class SideMenuComponent {
  navMenuItems = navbarData;
  sideMenuOpen = false;

  constructor(private readonly util: UtilService) {}

  toggleMenu() {
    this.sideMenuOpen = !this.sideMenuOpen;
  }

  clearNotification(label: string) {
    if (label !== 'Communication') {
      return;
    }
    this.util.navigateToCommunication();
  }
}
