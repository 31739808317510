import {
  CompanyDetailsView,
  LearningGroupDetailsView,
  StudentDetailsView,
} from '@core/services/api.service';
import { createAction } from '@ngrx/store';

export const SetCompany = createAction(
  '[Company] Set Company',
  (company: CompanyDetailsView | null) => ({ company })
);

export const SetGroup = createAction(
  '[Group] Set Group',
  (group: LearningGroupDetailsView | null) => ({ group })
);

export const SetStudent = createAction(
  '[Student] Set Student',
  (student: StudentDetailsView | null) => ({ student })
);
