import { createReducer, on } from '@ngrx/store';
import { SetLanguage, SetLastEmail } from './environment.actions';
import { defaultEnvironmentState, EnvironmentState } from './environment.state';

export const EnvironmentReducer = createReducer(
  defaultEnvironmentState,
  on(SetLanguage, (state, payload): EnvironmentState => {
    return {
      ...state,
      language: payload.language,
    };
  }),
  on(SetLastEmail, (state, payload): EnvironmentState => {
    return {
      ...state,
      lastEmail: payload.email,
    };
  })
);
