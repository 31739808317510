/** Used for rank ordering roles */
export enum Role {
  Student = 'Student',
  CompanyAdmin = 'CompanyAdmin',
  TrainingCenterAdmin = 'TrainingCenterAdmin',
  GoodLifeAdmin = 'GoodLifeAdmin',
}

export const Ranks = [
  Role.Student,
  Role.CompanyAdmin,
  Role.TrainingCenterAdmin,
  Role.GoodLifeAdmin,
];

export const ROLE_KEY = 'role';
