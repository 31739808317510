<div class="flex justify-between items-start">
  <h2 mat-dialog-title translate>
    {{ 'Document.Label.CertificateDetails' | translate }}
  </h2>
  <button mat-dialog-close>
    <img
      class="grey-filter"
      width="24px"
      src="assets/images/icons/cancel.svg"
      alt="" />
  </button>
</div>
<div>
  <mat-accordion>
    <div *ngFor="let item of data">
      <mat-expansion-panel
        class="mb-2"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex items-center">
              <img width="30px" src="{{ getIcon(item.action) }}" alt="" />
              <span class="ml-1 font-medium">{{
                'Document.StatusAction.' + item.action | translate
              }}</span>
            </div>
          </mat-panel-title>
          <mat-panel-title>
            <div class="flex">
              <span class="mr-1 font-medium">{{
                'Document.Label.CreatedBy' | translate
              }}</span>
              <span>{{ item.createdBy }}</span>
            </div>
          </mat-panel-title>
          <mat-panel-title>
            <div class="flex">
              <span class="mr-1 font-medium">{{
                'Document.Label.CreatedAt' | translate
              }}</span>
              <span>{{ item.createdAt | date : 'yyyy.MM.dd  hh:mm' }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex">
          <span class="mr-1 font-medium">{{
            'Document.Label.Note' | translate
          }}</span>
          <span>{{ item.text.length === 0 ? ' - ' : item.text }}</span>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-accordion>
</div>
<mat-dialog-actions class="gap-4">
  <button mat-dialog-close mat-flat-button>
    {{ 'General.Reset' | translate }}
  </button>
</mat-dialog-actions>
