import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiErrorDto, ApiServiceError } from '@core/services/api.service';
import { DialogService } from '../services/dialog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly defaultErrorDialogTitle = 'General.Dialog.Title.Error';
  private readonly defaultApiErrorDialogTitle = 'Error.Network.Title';
  private readonly defaultApiErrorDialogContent = 'Error.Network.Unknown';
  private readonly chunkLoadErrorDialogContent =
    'Error.Network.Name.ChunkLoadError';
  private readonly _baseErrorWordsRegex = new RegExp(
    /Validation failed: |-- | Severity: Error| - Error id: [a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/gm
  );

  constructor(
    private readonly dialogService: DialogService,
    private readonly translate: TranslateService,
    private readonly zone: NgZone
  ) {}
  handleError(error: Error) {
    let dialogTitle = this.defaultErrorDialogTitle;
    let dialogContent = error.message;
    if (error instanceof ApiServiceError) {
      dialogTitle = this.defaultApiErrorDialogTitle;
      dialogContent = this.defaultApiErrorDialogContent;
    } else if (this.isApiError(error)) {
      dialogTitle = this.defaultApiErrorDialogTitle;
      dialogContent = this.translateApiError(error);
    } else if (this.isChunkLoadError(error.message)) {
      dialogTitle = this.defaultErrorDialogTitle;
      dialogContent = this.chunkLoadErrorDialogContent;
    }
    console.error(error.message);
    this.zone.run(() => {
      this.dialogService.error(dialogTitle, dialogContent);
    });
  }

  isApiError(object: object): object is ApiErrorDto {
    const keys = ['statusCode', 'name', 'message', 'errors'];
    for (const key of keys) {
      if (!(key in object)) {
        return false;
      }
    }
    return true;
  }

  isChunkLoadError(message: string) {
    return message.includes('ChunkLoadError');
  }

  translateApiError(apiError: ApiErrorDto): string {
    console.error(apiError);
    let content = `${this.translateApiErrorName(apiError)}
      <br>${this.apiErrorMessageWithoutID(apiError.message)}`;
    if (apiError.errors) {
      for (const subError of this.translateApiSubErrors(apiError.errors)) {
        content += `<br>${subError}`;
      }
    }
    return content;
  }

  translateApiSubErrors(errors: { [key: string]: string[] }): string[] {
    const translated: string[] = [];
    for (const errorKey of Object.keys(errors)) {
      for (const subError of errors[errorKey].filter(sub => sub)) {
        translated.push(
          this.translate.instant(`Error.Network.SubError.${subError}`, {
            field: errorKey,
          }) as string
        );
      }
    }
    return translated;
  }

  translateApiErrorName(error: ApiErrorDto): string {
    return `<b>${
      this.translate.instant(`Error.Network.Name.${error.name}`) as string
    }</b>`;
  }

  apiErrorMessageWithoutID(message: string): string {
    return message.replace(this._baseErrorWordsRegex, '');
  }
}
