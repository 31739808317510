<div class="flex flex-col pt-4 noselect">
  <img
    class="self-end cursor-pointer"
    width="36px"
    src="assets/images/icons/buttonout_blue.svg"
    alt=""
    [@offset]="sideMenuOpen ? 'open' : 'closed'"
    (click)="toggleMenu()" />
  <div class="flex flex-col side-menu">
    <ul class="side-nav">
      <div *ngFor="let data of navMenuItems">
        <li
          *requiredRoles="data.requiredRoles"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="[data.routeLink]"
          #rla="routerLinkActive"
          (click)="clearNotification(data.label)">
          <a class="side-nav-link">
            <span
              class="w-[7px] mr-2"
              [class.active-span]="rla.isActive"></span>
            <span class="side-nav-link-icon">
              <img [src]="data.icon" />
            </span>
            <span class="side-nav-link-text" @fadeInOut *ngIf="sideMenuOpen">
              {{ 'SideMenu.' + data.label | translate }}
            </span>
          </a>
        </li>
      </div>
    </ul>
  </div>
</div>
