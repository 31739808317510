import { DateAdapter } from '@angular/material/core';
import { selectAccessToken } from '@core/ngrx-store/auth/auth.state';
import { selectLanguage } from '@core/ngrx-store/environment/environment.state';
import { ApiService } from '@core/services/api.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

export function AppInitializerFactory(
  translate: TranslateService,
  dateAdapter: DateAdapter<unknown>,
  store: Store,
  apiService: ApiService
) {
  return () => {
    translate.setDefaultLang(environment.internalization.language.default);
    const accessToken$ = store.select(selectAccessToken).pipe(
      take(1),
      tap(accessToken => {
        apiService.setAccessToken(accessToken);
      })
    );
    const lang$ = store.select(selectLanguage).pipe(
      take(1), // crucial
      switchMap(language => {
        dateAdapter.setLocale(language);
        return translate.use(language);
      })
    );
    return forkJoin([accessToken$, lang$]);
  };
}
