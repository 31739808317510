<div class="card-container">
  <div
    [routerLink]="type === cardType.Blue ? '/elearning/summary' : null"
    [ngClass]="
      type === cardType.Blue
        ? 'blue-card cursor-pointer'
        : type === cardType.Primary || type === cardType.Secondary
        ? 'simple-card'
        : 'wide-card'
    ">
    <span
      [ngClass]="
        type === cardType.Primary || type === cardType.Wide_Primary
          ? 'primary-title'
          : type === cardType.Secondary || type === cardType.Wide_Secondary
          ? 'secondary-title'
          : 'white-title'
      "
      >{{ title }}</span
    >
    <span
      [ngClass]="
        type === cardType.Primary || type === cardType.Wide_Primary
          ? 'primary-value'
          : type === cardType.Secondary || type === cardType.Wide_Secondary
          ? 'secondary-value'
          : 'white-value'
      "
      >{{ value }}</span
    >
  </div>
</div>
